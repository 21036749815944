import React, { Dispatch, SetStateAction, useState } from 'react'
import CustomModal from '../../../../../components/ui/CustomModal/CustomModal'
import { Formik } from 'formik'
import * as yup from "yup";
import { useAppDispatch } from '../../../../../store/store';
import { cryptoList, cryptoShortName } from '../../../../../constants/StaticData';
import { createWalletThunk } from '../../../../../store/reducers/ActionCreators';
import CustomDropdown from '../../../../../components/ui/CustomDropdown/CustomDropdown';
import MyInput from '../../../../../components/ui/MyInput/MyInput';
import TransparentButton from '../../../../../components/ui/TransparentButton/TransparentButton';
import Icon from '@ant-design/icons';
import { ReactComponent as Back } from "../../../../../assets/images/personalAccount/back.svg";
import MyBtn from '../../../../../components/ui/MyBtn/MyBtn';

type PropsType = {
    s: any;
    token: any;
    selectedWallet: any;
    walletsList: any;
    isModalOpen: any;
    setIsModalOpen: Dispatch<SetStateAction<any>>;
    setSuccessMessage: Dispatch<SetStateAction<any>>;
    setIsSuccessModalOpen: Dispatch<SetStateAction<any>>;
    setErrorMessage: Dispatch<SetStateAction<any>>;
    setIsErrorModalOpen: Dispatch<SetStateAction<any>>;
}

const RequestWalletModal = (props: PropsType) => {

    const {
        s,
        token,
        walletsList,
        isModalOpen,
        setIsModalOpen,
        setSuccessMessage,
        setIsSuccessModalOpen,
        setErrorMessage,
        setIsErrorModalOpen
    } = props;

    const dispatch = useAppDispatch();

    const [networkList, setNetworkList] = useState(['']);

    const validationSchemaTransactionByCard = yup.object({
        currency: yup.string().required("Required field"),
        network: yup.string().required("Required field")
    });

    const pendingCurrencies = walletsList
        .filter((wallet: any) => wallet.status === "Pending")
        .map((wallet: any) => wallet.currency);

    const cryptoListDropdown = Object.values(cryptoList)
        .map((crypto: any) => crypto.currency)
        .filter((cryptoName) => !pendingCurrencies.includes(cryptoName));

    const addWallet = (values: any) => {

        const currency: any = Object.values(cryptoList).find(
            (crypto: any) => crypto.currency === values.currency
        );

        let finalData = {
            ...values,
            currency: currency.value
        };

        dispatch(createWalletThunk(token, finalData)).then((res) => {
            if (res.status === 201) {
                setIsModalOpen(false);
                setSuccessMessage("Wallet has been created successfully");
                setIsSuccessModalOpen(true);
            } else {
                setErrorMessage("Opps something went wrong, contact support!");
                setIsErrorModalOpen(true);
            }
        });
    };

    return (
        <CustomModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
        >
            <Formik
                initialValues={{
                    currency: "",
                    network: "",
                    referral: "",
                }}
                validationSchema={validationSchemaTransactionByCard}
                onSubmit={addWallet}
            >
                {({ errors, touched, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={s.modalFormItem}>
                            <div className={s.modalTitle}>
                                Request a Wallet
                            </div>
                            <div className={s.modalDropdownSubtitle}>
                                * Select a Currency
                            </div>
                            <div className={s.modalDropdown}>
                                <CustomDropdown
                                    items={cryptoListDropdown}
                                    id={"currency"}
                                    name={"currency"}
                                    isError={errors.currency}
                                    touched={touched.currency}
                                    placeholder={""}
                                    onChange={(e: string) => setNetworkList(cryptoList[cryptoShortName[e]?.toUpperCase()]?.networks)}
                                />
                            </div>
                            {errors.currency && touched.currency && (
                                <div className={s.error}>{errors.currency}</div>
                            )}
                            <div className={s.modalDropdownSubtitle}>
                                * Select a Network
                            </div>
                            <div className={s.modalDropdown}>
                                <CustomDropdown
                                    items={networkList || []}
                                    id={"network"}
                                    name={"network"}
                                    isError={errors.network}
                                    touched={touched.network}
                                    placeholder={""}
                                />
                            </div>
                            {errors.network && touched.network && (
                                <div className={s.error}>{errors.network}</div>
                            )}

                        </div>
                        <div className={s.modalFormItem}>
                            <span className={s.modalDropdownSubtitle}>
                                Referral (Optional)
                            </span>
                            <div className={s.modalBlock}>
                                <MyInput
                                    id={"referral"}
                                    name={"referral"}
                                    placeholder={"Referral"}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className={s.modalButtonsWrapper}>
                            <TransparentButton
                                title={"Cancel"}
                                style={{ width: 180 }}
                                medium
                                icon={
                                    <Icon
                                        component={() => <Back />}
                                        rev={undefined}
                                    />
                                }
                                onClick={() =>
                                    setIsModalOpen(false)
                                }
                            />
                            <MyBtn
                                medium
                                style={{ width: 180 }}
                                title={"Continue"}
                            />
                        </div>
                    </form>
                )}
            </Formik>
        </CustomModal>
    )
}

export default RequestWalletModal;