import {createSelector} from "@reduxjs/toolkit";
import {ITemplate, ITransacton} from "../../../types/interfaces";
import {IFilters} from "./TransactionsSlice";
import {RootState} from "../../store";
export const selectAllTransactions = (state: RootState) => state.transactionsReducer.transactionsData
export const allTemplates = (state: RootState) => state.transactionsReducer.templates
export const selectFilters = (state: RootState) => state.transactionsReducer.filters
export const selectFiltersTemplates = (state: RootState) => state.transactionsReducer.filtersTemplates
export const allAccounts = (state: RootState) => state.userReducer.accountsList.accounts

export const selectTransactionsByFilter = createSelector<any, any>(
    selectAllTransactions,
    selectFilters,
    (allTransactions: any, selectFilters: IFilters) => {

        let sortedTransactions = [...allTransactions]

        const dateFromInTimestamp = Date.parse(selectFilters.from as string);

        const dateToInTimestamp = Date.parse(selectFilters.to as string);

        if (selectFilters.selectedType === 'ALL') {
            sortedTransactions = allTransactions
        }

        if (selectFilters.accountNumber === 'ALL') {
            sortedTransactions = allTransactions
        }

        if (selectFilters.accountNumber !== 'ALL') {
            sortedTransactions = sortedTransactions.filter((transaction : ITransacton) => transaction.fromAccount === selectFilters.accountNumber)
        }

        if (dateFromInTimestamp !== null && selectFilters.from !== '') {
            // @ts-ignore
            sortedTransactions = sortedTransactions.filter((transaction : ITransacton) => (transaction.created * 1000) > dateFromInTimestamp)
        }

        if (dateToInTimestamp !== null && selectFilters.to !== '') {
            // @ts-ignore
            sortedTransactions = sortedTransactions.filter((transaction : ITransacton) => (transaction.created * 1000) < (dateToInTimestamp + 86400000))
        }

        if (selectFilters.recipient && selectFilters.recipient !== '') {
            sortedTransactions = sortedTransactions.filter((transaction : ITransacton) => {
                if (transaction.transactionType === 'International Transaction') {
                    return transaction.ibanCode?.trim() === selectFilters.recipient?.trim()
                } else {
                    return transaction.toAccount?.trim() === selectFilters.recipient?.trim()
                }
            })
        }

        if (selectFilters.amount && selectFilters.amount !== '') {
            sortedTransactions = sortedTransactions.filter((transaction : ITransacton) => transaction.amount === Number(selectFilters.amount))
        }

        if (selectFilters.incoming && selectFilters.outgoing) {
            sortedTransactions = sortedTransactions.filter(
              (transaction: ITransacton) =>
                transaction.direction === 'IN' || transaction.direction === 'OUT'
            );
          } else if (selectFilters.incoming) {
            sortedTransactions = sortedTransactions.filter(
              (transaction: ITransacton) => transaction.direction === 'IN'
            );
          } else if (selectFilters.outgoing) {
            sortedTransactions = sortedTransactions.filter(
              (transaction: ITransacton) => transaction.direction === 'OUT'
            );
          }

        if (selectFilters.selectedType && selectFilters.selectedType !== 'ALL' && selectFilters.selectedType !== '' ) {
            sortedTransactions = sortedTransactions.filter((transaction : ITransacton) => transaction.transactionType === selectFilters.selectedType)
        }

        return sortedTransactions
    }
)

export const selectTemplatesByFilter = createSelector<any, any>(
    allTemplates,
    selectFiltersTemplates,
    (allTemplates: ITemplate[], selectFilters: IFilters) => {

        let sortedTemplates = [...allTemplates]

        if (selectFilters.templateName === 'ALL') {
            sortedTemplates = allTemplates
        }
        if (selectFilters.recipient === '') {
            sortedTemplates = allTemplates
        }

        if (selectFilters.accountNumber !== 'ALL' && selectFilters.accountNumber !== '') {
            sortedTemplates = sortedTemplates.filter((template: ITemplate) => template.account === selectFilters.accountNumber)
        }

        if (selectFilters.templateName && selectFilters.templateName !== 'ALL' ) {
            sortedTemplates = sortedTemplates.filter((template: ITemplate) => template.templateName === selectFilters.templateName)
        }

        if (selectFilters.recipient !== '') {

            sortedTemplates = sortedTemplates.filter((template: ITemplate) => {

                if (template.type === "International Transaction") {
                    return (
                        template?.recipient?.toLowerCase() === selectFilters?.recipient?.toLowerCase()
                    )
                } else {
                    return (
                        template?.toAccount?.toLowerCase() === selectFilters?.recipient?.toLowerCase()
                    )
                }
            })
        }

        return sortedTemplates
    }
)

export const selectAccountsByFilter = createSelector<any, any>(
    allAccounts,
    selectFilters,
    (allAccounts: any, selectFilters: IFilters) => {

        let sortedAccounts = [...allAccounts]

        if (selectFilters.paymentRegion === 'SWIFT (International Transfer)') {
            sortedAccounts = allAccounts
        }

        if (selectFilters.paymentRegion === 'SEPA (only for EUR)') {
            sortedAccounts = sortedAccounts.filter((account: any) => account.currency === 'EUR')
        }

        if (selectFilters.paymentRegion === 'SEPA INSTANT (only for EUR)') {
            sortedAccounts = sortedAccounts.filter((account: any) => account.currency === 'EUR')
        }

        if (selectFilters.paymentRegion === 'PAN AFRICA') {
            sortedAccounts = sortedAccounts.filter((account: any) => account.currency === 'USD')
        }

        if (selectFilters.paymentRegion === 'BACS (only for GBP)') {
            sortedAccounts = sortedAccounts.filter((account: any) => account.currency === 'GBP')
        }

        if (selectFilters.paymentRegion === 'FASTER PAYMENT (only for GBP)') {
            sortedAccounts = sortedAccounts.filter((account: any) => account.currency === 'GBP')
        }

        if (selectFilters.paymentRegion === 'CHAPS (only for GBP)') {
            sortedAccounts = sortedAccounts.filter((account: any) => account.currency === 'GBP')
        }

        if (selectFilters.paymentRegion === 'ACH (only for USD)') {
            sortedAccounts = sortedAccounts.filter((account: any) => account.currency === 'USD')
        }

        if (selectFilters.paymentRegion === 'INTERAC (only for CAD)') {
            sortedAccounts = sortedAccounts.filter((account: any) => account.currency === 'CAD')
        }

        if (selectFilters.paymentRegion === 'EFT (only for CAD)') {
            sortedAccounts = sortedAccounts.filter((account: any) => account.currency === 'CAD')
        }

        if (selectFilters.paymentRegion === 'Bill Payment (only for CAD)') {
            sortedAccounts = sortedAccounts.filter((account: any) => account.currency === 'CAD')
        }

        return sortedAccounts.filter((account: any) => account.status === "Active")
    }
)

export const selectTopUpByAccount = createSelector<any, any>(
    allAccounts,
    selectFilters,
    (allAccounts: any, selectFilters: IFilters) => {

        let sortedAccounts = [...allAccounts.filter((account: any) => account.status === "Active")]

        const selectedAccount = sortedAccounts.find((account) => account.number === selectFilters.accountNumber)

        return selectedAccount?.topUp
    }
)
