import React, { Dispatch, SetStateAction, useState } from 'react'
import CustomModal from '../../../../../components/ui/CustomModal/CustomModal'
import { Formik } from 'formik'
import * as yup from "yup";
import { useAppDispatch } from '../../../../../store/store';
import { createMercuryoTransactionThunk } from '../../../../../store/reducers/ActionCreators';
import CustomDropdown from '../../../../../components/ui/CustomDropdown/CustomDropdown';
import MyInput from '../../../../../components/ui/MyInput/MyInput';
import TransparentButton from '../../../../../components/ui/TransparentButton/TransparentButton';
import Icon from '@ant-design/icons';
import { ReactComponent as Back } from "../../../../../assets/images/personalAccount/back.svg";
import MyBtn from '../../../../../components/ui/MyBtn/MyBtn';
import CustomDropdownForAccounts from '../../../../../components/ui/CustomDropdownForAccounts/CustomDropdownForAccounts';

type PropsType = {
  s: any;
  token: any;
  usdtAccounts: any;
  isModalOpen: any;
  setIsModalOpen: Dispatch<SetStateAction<any>>;
  setSuccessMessage: Dispatch<SetStateAction<any>>;
  setIsSuccessModalOpen: Dispatch<SetStateAction<any>>;
  setErrorMessage: Dispatch<SetStateAction<any>>;
  setIsErrorModalOpen: Dispatch<SetStateAction<any>>;
}

const WithdrawModal = (props: PropsType) => {

  const {
    s,
    token,
    usdtAccounts,
    isModalOpen,
    setIsModalOpen,
    setSuccessMessage,
    setIsSuccessModalOpen,
    setErrorMessage,
    setIsErrorModalOpen
  } = props;

  const dispatch = useAppDispatch();

  const [selectedNetwork, setSelectedNetwork] = useState("");

  const validationSchemaRequestWithdraw = yup.object({
    account: yup.string().required("Required field"),
    network: yup.string().required("Required field"),
    address: yup
      .string()
      .required("Required field")
      .test("isValidAddress", "Invalid wallet address", function (value) {
        if (selectedNetwork === "TRON") {
          return /^(T[a-zA-Z0-9]{33})$/.test(value || "");
        } else if (selectedNetwork === "ETHEREUM") {
          return /^(0x[a-fA-F0-9]{40})$/.test(value || "");
        }
        return false;
      }),
    amount: yup.string()
      .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits")
      .test({
        message: 'One of the fields is required',
        test: function (value) {
          const { toAmount } = this.parent;
          if (!toAmount) return value !== undefined && value !== null && value !== '';
          return true
        },
      })
  });

  const requestWithdraw = async (values: any) => {

    const finalValues = {
      type: "withdraw",
      ...values,
    }

    await dispatch(
      createMercuryoTransactionThunk(
        token,
        finalValues
      )
    ).then((res) => {
      if (res.data === 201) {
        setIsModalOpen(false);
        setSuccessMessage("Transaction has been successfully made");
        setIsSuccessModalOpen(true);
      } else {
        setIsModalOpen(false);
        setErrorMessage("Opps something went wrong, please try again!");
        setIsErrorModalOpen(true);
      }
    });
  };

  return (
    <CustomModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <Formik
        initialValues={{
          account: "",
          network: "",
          address: "",
          amount: ""
        }}
        validationSchema={validationSchemaRequestWithdraw}
        onSubmit={requestWithdraw}
      >
        {({ errors, touched, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={s.modalTitle}>
              Withdraw USDT (External Wallet)
            </div>
            <div className={s.modalFormItem}>
              <div className={s.modalDropdownSubtitle}>
                * Select a USDT Account
              </div>
              <div className={s.modalDropdown}>
                <CustomDropdownForAccounts
                  id={'account'}
                  name={'account'}
                  items={usdtAccounts}
                  isError={errors.network}
                  touched={touched.network}
                  disableZeroBalance={true}
                />
              </div>
              {errors.account && touched.account && (
                <div className={s.error}>{errors.account}</div>
              )}
            </div>
            <div className={s.modalFormItem}>
              <div className={s.modalDropdownSubtitle}>
                * Select a Network (External Wallet)
              </div>
              <div className={s.modalDropdown}>
                <CustomDropdown
                  items={['TRON', 'ETHEREUM']}
                  id={"network"}
                  name={"network"}
                  isError={errors.network}
                  touched={touched.network}
                  placeholder={""}
                  onChange={(e) => setSelectedNetwork(e)}
                />
              </div>
              {errors.network && touched.network && (
                <div className={s.error}>{errors.network}</div>
              )}
            </div>
            <div className={s.modalFormItem}>
              <span className={s.modalDropdownSubtitle}>
                Wallet Address
              </span>
              <div className={s.modalBlock}>
                <MyInput
                  id={"address"}
                  name={"address"}
                  placeholder={"Wallet Address"}
                  onChange={handleChange}
                  isError={errors.address}
                  touched={touched.address}
                  disabled={!selectedNetwork}
                />
              </div>
              {errors.address && touched.address && (
                <div className={s.error}>{errors.address}</div>
              )}
            </div>
            <div className={s.modalFormItem}>
              <span className={s.modalDropdownSubtitle}>
                Amount
              </span>
              <div className={s.modalBlock}>
                <MyInput
                  id={"amount"}
                  name={"amount"}
                  placeholder={"Amount"}
                  onChange={handleChange}
                  isError={errors.amount}
                  touched={touched.amount}
                />
              </div>
              {errors.amount && touched.amount && (
                <div className={s.error}>{errors.amount}</div>
              )}
            </div>
            <div className={s.modalButtonsWrapper}>
              <TransparentButton
                title={"Cancel"}
                style={{ width: 180 }}
                medium
                icon={
                  <Icon
                    component={() => <Back />}
                    rev={undefined}
                  />
                }
                onClick={() =>
                  setIsModalOpen(false)
                }
              />
              <MyBtn
                medium
                style={{ width: 180 }}
                title={"Continue"}
              />
            </div>
          </form>
        )}
      </Formik>
    </CustomModal>
  )
}

export default WithdrawModal;