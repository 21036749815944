import React, { ChangeEvent, MouseEvent } from "react";
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import { ReactComponent as Plus } from "../../assets/images/personalAccount/plus.svg";
import attachedDoc from "../../assets/images/personalAccount/attached_document.svg";
import delete_document from "../../assets/images/personalAccount/delete_document.svg";
import rejectedDoc from "../../assets/images/personalAccount/rejected_document.svg";
import approvedDoc from "../../assets/images/common/approved.png";
import { FileTypes } from "../GenerateKycForm/Constants/KycConstants";
import s from "./FileInput.module.css";

type FileInputProps = {
  documents: any;
  inputId: string;
  allowedTypes: FileTypes[];
  identifier: string;
  maxFileSize: number;
  kycData?: any;
  uploadedFiles?: any;
  documentTitle?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onImageClick?: (event: MouseEvent<HTMLImageElement>) => void;
};

const FileInput = (props: FileInputProps) => {
  const {
    inputId,
    allowedTypes,
    documents,
    identifier,
    maxFileSize,
    onChange,
    onImageClick,
    uploadedFiles,
  } = props;

  const allowedTypesString = allowedTypes.join(", ");
  const maxSize = maxFileSize * 1024 * 1024;

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > maxSize) {
        alert(
          "The file size exceeds the " +
            maxFileSize +
            "MB limit. Please upload a smaller file."
        );
        event.target.value = "";
        return;
      }
      if (onChange) {
        onChange(event);
      }
    }
  };

  const handleButtonClick = (e: any) => {
    e.preventDefault();
    const inputElement = document.getElementById(inputId) as HTMLInputElement;
    if (inputElement) {
      inputElement.value = "";
      inputElement.click();
    }
  };

  return (
    <div className={s.attached_file_wrapper}>
      <div className={s.btn_block}>
        <TransparentButton
          icon={<Icon component={() => <Plus />} />}
          title={"Add file"}
          onClick={handleButtonClick}
        />
        <input
          type="file"
          accept={allowedTypesString}
          id={inputId}
          className={s.hidden_input}
          onChange={handleFileChange}
        />
      </div>

      <div className={s.attached_file}>
        {props.kycData?.status &&
          props.kycData?.files?.map((item: any, index: number) => {
            if (item.fileType !== identifier || documents[identifier])
              return null;

            if (item.status === "Rejected") {
              return (
                <React.Fragment key={index}>
                  <img src={rejectedDoc} alt="" />
                  <span className={s.rejected_doc_name}>
                    {item.fileType}&nbsp;<b>-&nbsp;[Rejected]</b>
                  </span>
                </React.Fragment>
              );
            }

            if (item.status === "Confirmed") {
              return (
                <React.Fragment key={index}>
                  <img src={approvedDoc} alt="" />
                  <span className={s.approved_doc_name}>
                    {item.fileType}&nbsp;<b>-&nbsp;[Approved]</b>
                  </span>
                </React.Fragment>
              );
            }

            return null;
          })}

        {documents[identifier]?.name && (
          <>
            <img src={attachedDoc} alt="" />
            <span className={s.attached_file_name}>
              {documents[identifier].name}
            </span>
            <img
              onClick={onImageClick}
              className={s.delete_doc}
              src={delete_document}
              alt=""
            />
          </>
        )}

        {!documents[identifier] &&
          uploadedFiles &&
          uploadedFiles[identifier] === true && (
            <div className={s.uploaded_doc_name}>
              {identifier}&nbsp;-&nbsp;[Uploaded]
            </div>
          )}
      </div>
    </div>
  );
};

export default FileInput;
