import React, {useEffect, useState} from 'react';
import s from './NavBar.module.css'
import { NavLink, useLocation, useNavigate} from "react-router-dom";
import classnames from "classnames";
import closePopup from "../../../assets/images/mobile/close_popup.svg"
import logo from "../../../assets/images/common/navbar-logo.svg"
import TransparentButton from "../../ui/TransparentButton/TransparentButton";
import SideMenu from "../../SideMenu/SideMenu";
import {useAppSelector} from "../../../hooks/redux";

type propsType = {
    isActive: boolean
    setIsActive: (boolean: boolean) => void
}
const NavBar = ({isActive, setIsActive}: propsType) => {

    const [IsPersonalSideMenu, setIsPersonalSideMenu] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const isLogin = useAppSelector(state => state.userReducer.user.userToken)

    useEffect(() => {
        if (
            location.pathname.includes("login") ||
            location.pathname.includes("sign_up") ||
            location.pathname.includes("personal_account")
        ) {
            setIsPersonalSideMenu(true)
        } else {
            setIsPersonalSideMenu(false)
        }
    }, [location.pathname])

    const handleNavigate = (path: any) => {
        setIsActive(false)
        navigate(path)
    }

    return (
        <>
            {
                IsPersonalSideMenu
                    ? <div className={classnames(s.myPopup, isActive ? s.active : s.inactive)}>
                        <SideMenu isSide setIsActive={setIsActive}/>
                    </div>
                    : <div className={classnames(s.myPopup, isActive ? s.active : s.inactive)}>
                        <div className={s.close_img_block}>
                            <NavLink to={"/login"} className={s.link} onClick={() => setIsActive(false)}>
                                <img src={logo} alt="" className={s.mobile_logo}/>
                            </NavLink>
                            <img src={closePopup} className={s.closeImg} alt="" onClick={() => setIsActive(false)}/>
                        </div>
                        <div className={s.buttons_block}>
                            { isLogin
                                ? <TransparentButton title={"Back"} onClick={() => handleNavigate(-1)}/>
                                : <TransparentButton title={"Log In"} onClick={() => handleNavigate("/login")}/>
                            }
                        </div>
                    </div>
            }
        </>
    );
};

export default NavBar;
