import React, { Dispatch, SetStateAction, useRef } from "react";
import MyInput from "../../../ui/MyInput/MyInput";
import { Field, useFormikContext } from "formik";
import {
    getPhoneNumberList,
    BusinessKycValues,
    companyTypes,
    natureOfBusiness,
} from "../../Constants/KycConstants";
import SelectWithSearch from "../../../ui/SelectWithSearch/SelectWithSearch";
import classnames from "classnames";
import { countries } from "../../../../constants/StaticData";
import CustomDropdown from "../../../ui/CustomDropdown/CustomDropdown";
import { useAppSelector } from "../../../../hooks/redux";
import { Checkbox, Col, DatePicker, Row } from "antd";
import dayjs from "dayjs";
import { dateFormat } from "../../../../constants/KycConstants";
import legalApplicationIcon from "../../../../assets/images/personalAccount/legal_account_application.png";
import DocumentBlock from "../../../DocumentBlock/DocumentBlock";
import legalApplicationFormIcon from "../../../../assets/images/personalAccount/download_legal_application.svg";
import proofOfAddressIcon from "../../../../assets/images/personalAccount/proof_of_address.png";
import companyCharterIcon from "../../../../assets/images/personalAccount/company_charter.png";
import registrationSertificateIcon from "../../../../assets/images/personalAccount/registration_sertificate.png";
import beneficiaryIdDocIcon from "../../../../assets/images/personalAccount/beneficiary_identity.png";
import beneficiaryIdcpIcon from "../../../../assets/images/personalAccount/beneficiary_idcp.png";
import BeneficiaryProofOfAddressIcon from "../../../../assets/images/personalAccount/proof_of_address.png";
import RegisterDirectorIcon from "../../../../assets/images/personalAccount/register_director.png";
import RegisterShareholderIcon from "../../../../assets/images/personalAccount/register_shareholder.png";
import PoaIcon from "../../../../assets/images/personalAccount/poa.png";
import licenseIcon from "../../../../assets/images/personalAccount/license.png";
import idCardIcon from "../../../../assets/images/personalAccount/idCard.png";
import TransparentButton from "../../../ui/TransparentButton/TransparentButton";
import { ReactComponent as Back } from "../../../../assets/images/personalAccount/back.svg";
import Icon from "@ant-design/icons";
import brandConfig from "../../../../config/config";
import MyBtn from "../../../ui/MyBtn/MyBtn";
import { FinancialInstitutionHelper } from "../../../../helpers/KycFormHelper";

type PropsType = {
    s: any;
    documents: any;
    current: any;
    date: any;
    kycData: any;
    editModeMissingDoc: string[];
    createModeMissingDoc: string[];
    setDate: Dispatch<SetStateAction<any>>;
    setDocuments: Dispatch<SetStateAction<any>>;
    setCurrent: Dispatch<SetStateAction<any>>;
};

export const KycFormBusiness = (props: PropsType) => {
    const {
        s,
        documents,
        current,
        date,
        kycData,
        createModeMissingDoc,
        editModeMissingDoc,
        setDate,
        setDocuments,
        setCurrent,
    } = props;

    const phoneList = useAppSelector(
        (state) => state.userReducer.user.phoneList
    );

    const { values, errors, touched, handleChange } =
        useFormikContext<BusinessKycValues>();

    const firstNameRef = useRef<any>(null!);

    const sourcesOfWealthHandlerChange = (
        e: any,
        meta: any,
        form: any,
        name: string
    ) => {
        form.setFieldValue("otherSourcesOfWealth", "");
        form.setFieldValue("isOtherSourcesOfWealth", false);
        const { checked } = e.target;
        if (checked) {
            form.setFieldValue("sourcesOfWealth", [
                ...meta.value.sourcesOfWealth,
                name,
            ]);
        } else {
            const filteredArr = meta.value.sourcesOfWealth.filter(
                (i: string) => i !== name
            );
            form.setFieldValue("sourcesOfWealth", filteredArr);
        }
    };

    return (
        <>
            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>* Company name</div>
                    <MyInput
                        id="companyName"
                        isError={errors.companyName}
                        name="companyName"
                        onChange={handleChange}
                        value={values.companyName}
                        touched={touched.companyName}
                    />
                    {errors.companyName && (
                        <div className={s.error_message}>
                            {errors.companyName}
                        </div>
                    )}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>* Company Country</div>
                    <SelectWithSearch
                        id="countryName"
                        name="countryName"
                        isError={errors.countryName}
                        touched={touched.countryName}
                        items={countries}
                    />
                    {errors.countryName && touched.countryName && (
                        <div className={s.error_message}>
                            {errors.countryName}
                        </div>
                    )}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>* Phone number</div>
                    <CustomDropdown
                        items={getPhoneNumberList(phoneList)}
                        placeholder={"Choose your phone number"}
                        id="phoneNumber"
                        name="phoneNumber"
                        isError={errors.phoneNumber}
                        touched={touched.phoneNumber}
                    />
                    {errors.phoneNumber && (
                        <div className={s.error_message}>
                            {errors.phoneNumber}
                        </div>
                    )}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>* company city</div>
                    <MyInput
                        id="city"
                        name="city"
                        onChange={handleChange}
                        value={values.city}
                        isError={errors.city}
                        touched={touched.city}
                    />
                    {errors.city && (
                        <div className={s.error_message}>{errors.city}</div>
                    )}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * company street (address)
                    </div>
                    <MyInput
                        id="address"
                        name="address"
                        onChange={handleChange}
                        value={values.address}
                        isError={errors.address}
                        touched={touched.address}
                    />
                    {errors.address && (
                        <div className={s.error_message}>{errors.address}</div>
                    )}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>* post code</div>
                    <MyInput
                        id="postalCode"
                        name="postalCode"
                        onChange={handleChange}
                        value={values.postalCode}
                        isError={errors.postalCode}
                        touched={touched.postalCode}
                    />
                    {errors.postalCode && (
                        <div className={s.error_message}>
                            {errors.postalCode}
                        </div>
                    )}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>* company email</div>
                    <MyInput
                        id="email"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                        isError={errors.email}
                        touched={touched.email}
                    />
                    {errors.email && (
                        <div className={s.error_message}>{errors.email}</div>
                    )}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>* company type</div>
                    <CustomDropdown
                        items={companyTypes}
                        placeholder={"Choose company type"}
                        id="companyType"
                        name="companyType"
                        isError={errors.companyType}
                        touched={touched.companyType}
                    />
                    {errors.companyType && (
                        <div className={s.error_message}>
                            {errors.companyType}
                        </div>
                    )}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>registration date</div>
                    <DatePicker
                        suffixIcon={null}
                        className={s.dateStyle}
                        defaultValue={dayjs(new Date(), dateFormat)}
                        value={dayjs(date.from)}
                        onChange={(_, dateString) => {
                            if (!dateString) {
                                setDate({
                                    ...date,
                                    from: new Date().toDateString(),
                                });
                            } else {
                                setDate({ ...date, from: dateString });
                            }
                        }}
                        format={dateFormat}
                    />
                </div>
            </div>

            {values.companyType === "Financial Institution" && (
                <div className={s.row}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            * financial regulator
                        </div>
                        <MyInput
                            id="financialRegulator"
                            name="financialRegulator"
                            onChange={handleChange}
                            value={values.financialRegulator}
                            isError={errors.financialRegulator}
                            touched={touched.financialRegulator}
                        />
                        {errors.financialRegulator && (
                            <div className={s.error_message}>
                                {errors.financialRegulator}
                            </div>
                        )}
                    </div>

                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            * regulatory license number
                        </div>
                        <MyInput
                            id="regulatoryLicenseNumber"
                            name="regulatoryLicenseNumber"
                            onChange={handleChange}
                            value={values.regulatoryLicenseNumber}
                            isError={errors.regulatoryLicenseNumber}
                            touched={touched.regulatoryLicenseNumber}
                        />
                        {errors.regulatoryLicenseNumber && (
                            <div className={s.error_message}>
                                {errors.regulatoryLicenseNumber}
                            </div>
                        )}
                    </div>
                </div>
            )}

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>Registration Number</div>
                    <MyInput
                        id="registrationNumber"
                        name="registrationNumber"
                        onChange={handleChange}
                        value={values.registrationNumber}
                        isError={errors.registrationNumber}
                        touched={touched.registrationNumber}
                    />
                    {errors.registrationNumber && (
                        <div className={s.error_message}>
                            {errors.registrationNumber}
                        </div>
                    )}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>Nature of Business</div>
                    <CustomDropdown
                        items={natureOfBusiness}
                        placeholder={"Choose nature of business"}
                        id="natureOfBusiness"
                        name="natureOfBusiness"
                        isError={errors.natureOfBusiness}
                        touched={touched.natureOfBusiness}
                    />
                    {errors.natureOfBusiness && (
                        <div className={s.error_message}>
                            {errors.natureOfBusiness}
                        </div>
                    )}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>** website</div>
                    <MyInput
                        id="website"
                        name="website"
                        onChange={handleChange}
                        value={values.website}
                        isError={errors.website}
                        touched={touched.website}
                    />
                    {errors.website && (
                        <div className={s.error_message}>{errors.website}</div>
                    )}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>** Marketing strategy</div>
                    <MyInput
                        id="marketingStrategy"
                        name="marketingStrategy"
                        onChange={handleChange}
                        value={values.marketingStrategy}
                        isError={errors.marketingStrategy}
                        touched={touched.marketingStrategy}
                    />
                    {errors.marketingStrategy && (
                        <div className={s.error_message}>
                            {errors.marketingStrategy}
                        </div>
                    )}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>* Industry Id</div>
                    <MyInput
                        id="industryId"
                        name="industryId"
                        onChange={handleChange}
                        value={values.industryId}
                        isError={errors.industryId}
                        touched={touched.industryId}
                    />
                    {errors.industryId && (
                        <div className={s.error_message}>
                            {errors.industryId}
                        </div>
                    )}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>* Tax Number</div>
                    <MyInput
                        id="taxNumber"
                        name="taxNumber"
                        onChange={handleChange}
                        value={values.taxNumber}
                        isError={errors.taxNumber}
                        touched={touched.taxNumber}
                    />
                    {errors.taxNumber && (
                        <div className={s.error_message}>
                            {errors.taxNumber}
                        </div>
                    )}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block} ref={firstNameRef}>
                    <div className={s.input_label}>
                        * AUTHORIZED PERSON FIRST NAME
                    </div>
                    <MyInput
                        id="firstName"
                        name="firstName"
                        onChange={handleChange}
                        value={values.firstName}
                        isError={errors.firstName}
                        touched={touched.firstName}
                    />
                    {errors.firstName && touched.firstName && (
                        <div className={s.error_message}>
                            {errors.firstName}
                        </div>
                    )}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * AUTHORIZED PERSON LAST NAME
                    </div>
                    <MyInput
                        id="lastName"
                        name="lastName"
                        onChange={handleChange}
                        value={values.lastName}
                        isError={errors.lastName}
                        touched={touched.lastName}
                    />
                    {errors.lastName && touched.lastName && (
                        <div className={s.error_message}>{errors.lastName}</div>
                    )}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>* Date of Birth</div>
                    <DatePicker
                        suffixIcon={null}
                        className={s.dateStyle}
                        defaultValue={dayjs(new Date(), dateFormat)}
                        value={dayjs(date.birth)}
                        onChange={(_, dateString) => {
                            if (!dateString) {
                                setDate({
                                    ...date,
                                    birth: new Date().toDateString(),
                                });
                            } else {
                                setDate({ ...date, birth: dateString });
                            }
                        }}
                        format={dateFormat}
                    />
                </div>
                <div className={s.input_block}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            * AUTHORIZED PERSON COUNTRY OF RESIDENCE
                        </div>
                        <SelectWithSearch
                            id="residenceCountryName"
                            name="residenceCountryName"
                            isError={errors.residenceCountryName}
                            touched={touched.residenceCountryName}
                            items={countries}
                        />
                        {errors.residenceCountryName &&
                            touched.residenceCountryName && (
                                <div className={s.error_message}>
                                    {errors.residenceCountryName}
                                </div>
                            )}
                    </div>
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * AUTHORIZED PERSON CITIZENSHIP
                    </div>
                    <SelectWithSearch
                        id="authorizedCitizenshipName"
                        name="authorizedCitizenshipName"
                        isError={errors.authorizedCitizenshipName}
                        touched={touched.authorizedCitizenshipName}
                        items={countries}
                    />
                    {errors.authorizedCitizenshipName &&
                        touched.authorizedCitizenshipName && (
                            <div className={s.error_message}>
                                {errors.authorizedCitizenshipName}
                            </div>
                        )}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * AUTHORIZED PERSON CITY
                    </div>
                    <MyInput
                        id="authorizedPersonCity"
                        name="authorizedPersonCity"
                        onChange={handleChange}
                        value={values.authorizedPersonCity}
                        isError={errors.authorizedPersonCity}
                        touched={touched.authorizedPersonCity}
                    />
                    {errors.authorizedPersonCity &&
                        touched.authorizedPersonCity && (
                            <div className={s.error_message}>
                                {errors.authorizedPersonCity}
                            </div>
                        )}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * AUTHORIZED PERSON STREET (ADDRESS)
                    </div>
                    <MyInput
                        id="authorizedPersonAddress"
                        name="authorizedPersonAddress"
                        onChange={handleChange}
                        value={values.authorizedPersonAddress}
                        isError={errors.authorizedPersonAddress}
                        touched={touched.authorizedPersonAddress}
                    />
                    {errors.authorizedPersonAddress &&
                        touched.authorizedPersonAddress && (
                            <div className={s.error_message}>
                                {errors.authorizedPersonAddress}
                            </div>
                        )}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * AUTHORIZED PERSON POSTAL CODE
                    </div>
                    <MyInput
                        id="authorizedPersonPostalCode"
                        name="authorizedPersonPostalCode"
                        onChange={handleChange}
                        value={values.authorizedPersonPostalCode}
                        isError={errors.authorizedPersonPostalCode}
                        touched={touched.authorizedPersonPostalCode}
                    />
                    {errors.authorizedPersonPostalCode &&
                        touched.authorizedPersonPostalCode && (
                            <div className={s.error_message}>
                                {errors.authorizedPersonPostalCode}
                            </div>
                        )}
                </div>
            </div>

            <div className={s.row}>
                <div className={classnames(s.input_block)}>
                    <div className={s.input_label}>
                        * Sources of wealth or income
                    </div>
                    <div
                        role="group"
                        aria-labelledby="checkbox-group"
                        className={s.sources_of_wealth_wrapper}
                    >
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props.meta.value.sourcesOfWealth.includes(
                                                "Inheritances"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Inheritances"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Inheritances
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props.meta.value.sourcesOfWealth.includes(
                                                "Investments"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Investments"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Investments
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props.meta.value.sourcesOfWealth.includes(
                                                "Business ownership interests"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Business ownership interests"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Business ownership interests
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props.meta.value.sourcesOfWealth.includes(
                                                "Employment income"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Employment income"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Employment income
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props.meta.value.sourcesOfWealth.includes(
                                                "Personal savings"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Personal savings"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Personal savings
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props.meta.value.sourcesOfWealth.includes(
                                                "Pension releases"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Pension releases"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Pension releases
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props.meta.value.sourcesOfWealth.includes(
                                                "Share sales and dividends"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Share sales and dividends"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Share sales and dividends
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props.meta.value.sourcesOfWealth.includes(
                                                "Property sales"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Property sales"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Property sales
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props.meta.value.sourcesOfWealth.includes(
                                                "Gambling winnings"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Gambling winnings"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Gambling winnings
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props.meta.value.sourcesOfWealth.includes(
                                                "Inheritances and gifts"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Inheritances and gifts"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Inheritances and gifts
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props.meta.value.sourcesOfWealth.includes(
                                                "Compensation from legal rulings"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Compensation from legal rulings"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Compensation from legal rulings
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={
                                                props.meta.value
                                                    .isOtherSourcesOfWealth
                                            }
                                            onChange={(e) => {
                                                const { checked } = e.target;
                                                if (checked) {
                                                    props.form.setFieldValue(
                                                        "sourcesOfWealth",
                                                        []
                                                    );
                                                }
                                                props.form.setFieldValue(
                                                    "isOtherSourcesOfWealth",
                                                    checked
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>Other</span>
                        </label>
                    </div>
                    {errors.sourcesOfWealth &&
                        values.sourcesOfWealth.length === 0 && (
                            <div className={s.error_message}>
                                {errors.sourcesOfWealth}
                            </div>
                        )}
                </div>
            </div>

            <div className={s.row}>
                {
                    // @ts-ignore
                    values.isOtherSourcesOfWealth && (
                        <div className={s.input_block}>
                            <div className={s.input_label}>* Other</div>
                            <MyInput
                                id="otherSourcesOfWealth"
                                name="otherSourcesOfWealth"
                                onChange={handleChange}
                                value={values.otherSourcesOfWealth}
                                isError={errors.otherSourcesOfWealth}
                                touched={touched.otherSourcesOfWealth}
                            />
                            {errors.otherSourcesOfWealth && (
                                <div className={s.error_message}>
                                    {errors.otherSourcesOfWealth}
                                </div>
                            )}
                        </div>
                    )
                }
                <div className={s.input_block}></div>
            </div>

            <div className={s.row}>
                <div className={classnames(s.input_block)}>
                    <div
                        className={classnames(
                            s.input_label,
                            errors.currencies && s.doc_error
                        )}
                        id="checkbox-group"
                    >
                        * Add a new account
                    </div>
                    <div
                        role="group"
                        aria-labelledby="checkbox-group"
                        className={s.checkbox_wrapper}
                    >
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="CAD"
                            />
                            <span className={s.checkbox_label}>CAD</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="EUR"
                            />
                            <span className={s.checkbox_label}>EUR</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="GBP"
                            />
                            <span className={s.checkbox_label}>GBP</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="USD"
                            />
                            <span className={s.checkbox_label}>USD</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="USDT"
                            />
                            <span className={s.checkbox_label}>USDT</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="SGD"
                            />
                            <span className={s.checkbox_label}>SGD</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="HKD"
                            />
                            <span className={s.checkbox_label}>HKD</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="JPY"
                            />
                            <span className={s.checkbox_label}>JPY</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="CNH"
                            />
                            <span className={s.checkbox_label}>CNH</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="AED"
                            />
                            <span className={s.checkbox_label}>AED</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="NGN"
                            />
                            <span className={s.checkbox_label}>NGN</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="CNY"
                            />
                            <span className={s.checkbox_label}>CNY</span>
                        </label>
                    </div>
                    {errors.currencies && (
                        <div className={s.error_message}>
                            {errors.currencies}
                        </div>
                    )}
                </div>
            </div>

            <div className={s.line}></div>

            <DocumentBlock
                title="Legal Account Application"
                documentType="accountApplication"
                isRequired={true}
                iconUrl={legalApplicationIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={["application/pdf"]}
                descriptionItems={[
                    <>
                        1. <img src={legalApplicationFormIcon} alt="Download Icon" />&nbsp;
                        <a
                            href="/Legal_Account_Application_form.pdf"
                            target="_blank"
                            className={s.download_link}
                        >
                            Download legal account application
                        </a>
                    </>,
                    "2. Fill in relevant information.",
                    "3. Upload filled and signed legal account application form.",
                ]}
                kycData={kycData}
            />

            <DocumentBlock
                title="Company Proof of Address"
                documentType="proofOfAddress"
                isRequired={true}
                iconUrl={proofOfAddressIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={["application/pdf"]}
                descriptionText="Acceptable documents are:"
                descriptionItems={[
                    "Bank statement with the date of issue and the name of the person (the document must be not older than 3 months).",
                    "Utility bill for gas, electricity, water, internet, etc. linked to the property (the document must not be older than 3 months).",
                ]}
                kycData={kycData}
            />

            <DocumentBlock
                title="Articles & Memorandums of Association"
                documentType="articlesMemorandums"
                isRequired={true}
                iconUrl={companyCharterIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={["application/pdf"]}
                descriptionText="Upload company Charter/Statute/Memorandum/Articles of Association/Memorandum of Association or any other relevant statutory documents."
                kycData={kycData}
            />

            <DocumentBlock
                title="Registration Certificate"
                documentType="registrationCertificate"
                isRequired={true}
                iconUrl={registrationSertificateIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={["application/pdf"]}
                descriptionText="Upload Certificate of Incorporation or Certificate of enterprise registration."
                kycData={kycData}
            />

            <DocumentBlock
                title="Articles of Association"
                documentType="articlesOfAssociation"
                isRequired={true}
                iconUrl={registrationSertificateIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={["application/pdf"]}
                descriptionText="Upload Articles of Association."
                kycData={kycData}
            />

            <DocumentBlock
                title="Beneficiary Identity document (Passport, ID Card, Driving license)"
                documentType="beneficiaryIdDocument"
                isRequired={true}
                iconUrl={beneficiaryIdDocIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={8}
                allowedFileTypes={[
                    "application/pdf",
                    ".png",
                    ".jpg",
                    ".jpeg",
                    ".pdf",
                ]}
                descriptionText="Good photo tips:"
                descriptionItems={[
                    "A color photo contains full document in clear focus and the data can be read easily.",
                    "There're no light glares impeding document readability.",
                    "A photo was not edited in graphic applications (we will definitely check).",
                ]}
                note="Please upload Identity document (Passport, ID Card, Driving license) photos of each Shareholder with more than 25% ownership, all Board Members and Directors."
                kycData={kycData}
            />

            <DocumentBlock
                title="Beneficiary IDCP"
                documentType="beneficiaryIdcp"
                isRequired={true}
                iconUrl={beneficiaryIdcpIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={[
                    "application/pdf",
                    ".png",
                    ".jpg",
                    ".jpeg",
                    ".pdf",
                ]}
                descriptionText={`Upload an ID confirmation photo (selfie of the Beneficiary holding in his hands his ID and a paper with written name of platform "${brandConfig.companyNameSimple}", date (dd/mm/yyyy/) and Beneficiary's signature)`}
                note="Please upload Identity document (Passport, ID Card, Driving license) photos of each Shareholder with more than 25% ownership, all Board Members and Directors."
                kycData={kycData}
            />

            <DocumentBlock
                title="Beneficiary Proof of Address"
                documentType="beneficiaryProofOfAddress"
                isRequired={true}
                iconUrl={BeneficiaryProofOfAddressIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={["application/pdf"]}
                descriptionText="Acceptable documents are:"
                descriptionItems={[
                    "Bank statement with the date of issue and the name of the person (the document must be not older than 3 months).",
                    "Utility bill for gas, electricity, water, internet, etc. linked to the property (the document must not be older than 3 months).",
                ]}
                note="Please upload Identity document (Passport, ID Card, Driving license) photos of each Shareholder with more than 25% ownership, all Board Members and Directors."
                kycData={kycData}
            />

            <DocumentBlock
                title="Register of Director/Members"
                documentType="registerOfDirectorOrMembers"
                isRequired={true}
                iconUrl={RegisterDirectorIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={["application/pdf"]}
                descriptionText="Upload Register of Director/Members"
                kycData={kycData}
            />

            <DocumentBlock
                title="Register of Shareholders"
                documentType="registerOfShareholders"
                isRequired={true}
                iconUrl={RegisterShareholderIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={["application/pdf"]}
                descriptionText="Upload Register of Shareholders"
                kycData={kycData}
            />

            <DocumentBlock
                title="Partnership Deed"
                documentType="partnershipDeed"
                isRequired={false}
                iconUrl={PoaIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={["application/pdf"]}
                kycData={kycData}
            />

            <DocumentBlock
                title="Proof of Partnership Address"
                documentType="proofOfPartnershipAddress"
                isRequired={false}
                iconUrl={PoaIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={["application/pdf"]}
                kycData={kycData}
            />

            <DocumentBlock
                title="Register of Partners"
                documentType="registerOfPartners"
                isRequired={false}
                iconUrl={PoaIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={["application/pdf"]}
                kycData={kycData}
            />

            <DocumentBlock
                title="PoA (If applicable)"
                documentType="poA"
                isRequired={false}
                iconUrl={PoaIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={["application/pdf"]}
                descriptionText="If the company is represented by a person not authorized to do so by the company’s Articles of Association or equivalent document, please upload a Power of Attorney asserting the right of this person to represent company's interests."
                kycData={kycData}
            />

            <DocumentBlock
                title="Licence (If applicable)"
                documentType="license"
                isRequired={false}
                iconUrl={licenseIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={["application/pdf"]}
                descriptionText="If your business requires a license in order to operate, please upload it."
                kycData={kycData}
            />

            <DocumentBlock
                title="ID Card/Residence Permit Card"
                documentType="idCard"
                isRequired={false}
                iconUrl={idCardIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={["image/png", "image/jpeg", ".pdf"]}
                descriptionText="If you have National ID Card or Residence Permit Card, please upload it."
                kycData={kycData}
            />

            <DocumentBlock
                title="Back of ID Card/Residence Permit Card"
                documentType="backOfIdCard"
                isRequired={false}
                iconUrl={idCardIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={["image/png", "image/jpeg", ".pdf"]}
                descriptionText="If you uploaded ID Card/Driving license, please upload the back side of it."
                kycData={kycData}
            />

            <div className={s.required_fields}>* Required fields</div>

            <Row gutter={16}>
                <Col span={12} >
                    <TransparentButton
                        large
                        icon={
                            <Icon component={() => <Back />} rev={undefined} />
                        }
                        title={"Back"}
                        type={"button"}
                        onClick={() => setCurrent(current - 1)}
                    />
                </Col>
                <Col span={12} >
                    <MyBtn
                        title={"Next Step"}
                        type="submit"
                        onClick={() => console.log(errors)}
                    />
                </Col>
            </Row>

            <FinancialInstitutionHelper />
        </>
    );
};
