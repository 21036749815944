import React, { useEffect, useState } from "react";
import MyBtn from "../../../../components/ui/MyBtn/MyBtn";
import s from "./Wallets.module.css";
import CustomModal from "../../../../components/ui/CustomModal/CustomModal";
import { EllipsisOutlined } from "@ant-design/icons";
import { cryptoShortName } from "../../../../constants/StaticData";
import { Button, Dropdown, Layout, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { useAppSelector } from "../../../../hooks/redux";
import RequestTransactionModal from "./Modals/RequestTransactionModal";
import TransactionByCardModal from "./Modals/TransactionByCardModal";
import RequestWalletModal from "./Modals/RequestWalletModal";
import WithdrawModal from "./Modals/WithdrawModal";
import { getWalletListThunk, mercuryoLoginThunk } from "../../../../store/reducers/ActionCreators";
import { useAppDispatch } from "../../../../store/store";

const Wallets = () => {

    const token = useAppSelector((state) => state.userReducer.user.userToken);
    const accountsList = useAppSelector((store) => store.userReducer.accountsList.accounts);
    const usdtAccounts = accountsList?.filter((account: any) => account.status === "Active" && account.currency === "USDT");

    const [isRequestWalletModalOpen, setIsRequestWalletModalOpen] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isRequestTransactionModalOpen, setIsRequestTransactionModalOpen] = useState(false);
    const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [selectedWallet, setSelectedWallet]: any[any] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showCreateTransactionModal, setShowCreateTransactionModal] =
        useState(false);
    const [widgetCredentials, setWidgetCredentials]: any = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useAppDispatch();

    const walletsList = useAppSelector(
        (state) => state.walletReducer.walletsData
    );

    useEffect(() => {
        dispatch(getWalletListThunk(token));
    }, [dispatch, token])

    const handleMenuClick = async (key: string, record: any) => {
        const handlers: Record<string, (record: any) => void> = {
            transactionByCard: async (record: any) => {
                setSelectedWallet(record);
                setIsLoading(true);
                await dispatch(mercuryoLoginThunk(token, record.address)).then((res: any) => {
                    setWidgetCredentials(res.data.data)
                }).finally(() => {
                    setShowCreateTransactionModal(true);
                    setIsLoading(false);
                });
            }
        };

        const handler = handlers[key];
        if (handler) {
            handler(record);
        } else {
            console.warn(`No handler defined for action: ${key}`);
        }
    };

    const columns: ColumnsType<any> = [
        {
            title: "Crypto",
            dataIndex: "currency",
            render: (currency: string) => (
                <div className={s.cryptoDetail}>
                    <img
                        src={`/crypto/${cryptoShortName[currency]}.svg`}
                        alt={`Crypto Icon ${cryptoShortName[currency]}`}
                    />
                    <div className={s.cryptoText}>
                        <div>{currency}</div>
                    </div>
                </div>
            ),
        },
        {
            title: "Network",
            dataIndex: "network",
        },
        {
            title: "Wallet Name",
            dataIndex: "name",
        },
        {
            title: "Wallet Address",
            dataIndex: "address",
        },
        {
            title: "Balance",
            dataIndex: "balance",
            render: (balance: any) => balance || '--',
            width: 100
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (status: string) => {
                let color = "";
                switch (status) {
                    case "Pending":
                        color = "yellow";
                        break;
                    case "Approved":
                        color = "green";
                        break;
                    case "Rejected":
                        color = "red";
                        break;
                    default:
                        color = "default";
                        break;
                }

                return <Tag color={color}>{status}</Tag>;
            },
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => {
                return (
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    key: "transactionByCard",
                                    label: "Transaction by Card",
                                    // disabled: record.status !== "Approved",
                                }
                            ],
                            onClick: ({ key }) => handleMenuClick(key, record)
                        }}
                        trigger={["click"]}
                        disabled={record.status !== "Approved"}
                    >
                        <Button
                            shape="circle"
                            icon={<EllipsisOutlined />}
                            onClick={(e) => e.preventDefault()}
                            disabled={record.status !== "Approved"}
                        />
                    </Dropdown>
                );
            },
            width: 60
        },
    ];

    const renderWallets = () => (
        <div className={s.walletsContainer}>
            <div className={s.walletsTableWrapper}>
                <div className={s.titleWrapper}>
                    <div>Crypto Wallets</div>
                    <div className={s.titleButtonWrapper}>
                        <div className={s.buttonWrapper}>
                            <MyBtn
                                title={"Request Wallet"}
                                medium
                                onClick={() => setIsRequestWalletModalOpen(true)}
                                type={"button"}
                            />
                        </div>
                        <div className={s.buttonWrapper}>
                            <MyBtn
                                title={"Request Transaction"}
                                medium
                                onClick={() => setIsRequestTransactionModalOpen(true)}
                                type={"button"}
                            />
                        </div>
                        <div className={s.buttonWrapper}>
                            <MyBtn
                                title={"Withdraw"}
                                medium
                                onClick={() => {
                                    setIsWithdrawModalOpen(true);
                                }}
                                type={"button"}
                            />
                        </div>
                    </div>
                </div>
                <Layout>
                    <Table
                        bordered={false}
                        className={s.walletsTable}
                        columns={columns}
                        dataSource={walletsList}
                        pagination={{
                            pageSize: 10,
                            showTotal: (total, range) =>
                                `${range[0]}-${range[1]} of ${total} items`,
                        }}
                        /* Adjust scroll if you want horizontal scrolling on small screens */
                        scroll={{ x: "max-content" }}
                        rowKey="id"
                    />
                </Layout>
            </div>
        </div>
    );


    return (
        <>
            <CustomModal
                isModalOpen={isSuccessModalOpen}
                setIsModalOpen={setIsSuccessModalOpen}
            >
                <div className={s.status_flex}>
                    <div className={s.status_modal_title}>Success!</div>
                    <div className={s.status_modal_subtitle}>
                        {successMessage}
                    </div>
                    <div className={s.status_button_block}>
                        <MyBtn
                            title={"Ok"}
                            onClick={() => {
                                setIsSuccessModalOpen(false);
                            }}
                        />
                    </div>
                </div>
            </CustomModal>
            <CustomModal
                type="error"
                isModalOpen={isErrorModalOpen}
                setIsModalOpen={setIsErrorModalOpen}
            >
                <div className={s.status_flex}>
                    <div className={s.status_modal_title}>Oops!</div>
                    <div className={s.status_modal_subtitle}>
                        {errorMessage}
                    </div>
                    <div className={s.status_button_block}>
                        <MyBtn
                            buttonType="error"
                            title={"Ok"}
                            onClick={() => setIsErrorModalOpen(false)}
                        />
                    </div>
                </div>
            </CustomModal>

            {/* Withdraw Modal */}
            <WithdrawModal
                s={s}
                token={token}
                usdtAccounts={usdtAccounts}
                isModalOpen={isWithdrawModalOpen}
                setIsModalOpen={setIsWithdrawModalOpen}
                setErrorMessage={setErrorMessage}
                setIsErrorModalOpen={setIsErrorModalOpen}
                setSuccessMessage={setSuccessMessage}
                setIsSuccessModalOpen={setIsSuccessModalOpen}
            />

            {/* Request Wallet Modal */}
            <RequestWalletModal
                s={s}
                token={token}
                isModalOpen={isRequestWalletModalOpen}
                setIsModalOpen={setIsRequestWalletModalOpen}
                selectedWallet={selectedWallet}
                walletsList={walletsList}
                setErrorMessage={setErrorMessage}
                setIsErrorModalOpen={setIsErrorModalOpen}
                setSuccessMessage={setSuccessMessage}
                setIsSuccessModalOpen={setIsSuccessModalOpen}
            />

            {/* Transaction By Card Modal */}
            <TransactionByCardModal
                s={s}
                token={token}
                isLoading={isLoading}
                isModalOpen={showCreateTransactionModal}
                setIsModalOpen={setShowCreateTransactionModal}
                selectedWallet={selectedWallet}
                widgetCredentials={widgetCredentials}
                setErrorMessage={setErrorMessage}
                setIsErrorModalOpen={setIsErrorModalOpen}
                setSuccessMessage={setSuccessMessage}
                setIsSuccessModalOpen={setIsSuccessModalOpen}
            />

            {/* Request Transaction Modal */}
            <RequestTransactionModal s={s} usdtAccounts={usdtAccounts} isModalOpen={isRequestTransactionModalOpen} setIsModalOpen={setIsRequestTransactionModalOpen} />

            {renderWallets()}
        </>
    );
};

export default Wallets;
