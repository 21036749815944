import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { DatePicker, Popover } from "antd";
import info from "../../../assets/images/personalAccount/information.svg";
import dayjs from "dayjs";
import { RangePickerProps } from "antd/es/date-picker";
import MyInput from "../../ui/MyInput/MyInput";
import classnames from "classnames";
import { FormikProps } from "formik";
import MyTextarea from "../../ui/MyTextarea/MyTextarea";
import CustomCheckbox from "../../ui/customCheckbox/CustomCheckbox";
import invoice from "../../../assets/images/personalAccount/invoice.svg";
import TransparentButton from "../../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import { ReactComponent as Plus } from "../../../assets/images/personalAccount/plus.svg";
import { ReactComponent as Back } from "../../../assets/images/personalAccount/back.svg";
import attachedDoc from "../../../assets/images/personalAccount/attached_document.svg";
import delete_document from "../../../assets/images/personalAccount/delete_document.svg";
import MyBtn from "../../ui/MyBtn/MyBtn";
import { Persist } from "formik-persist";
import { selectAccountsByFilter } from "../../../store/reducers/TransactionsSlice/selector";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

type PropsType = {
    s: any;
    id: any;
    values: any;
    touched: any;
    errors: any;
    currency: any;
    paymentType: any;
    date: any;
    setDate: Dispatch<SetStateAction<any>>;
    handleChange: FormikProps<any>["handleChange"];
    setCurrency: Dispatch<SetStateAction<any>>;
    setPaymentType: Dispatch<SetStateAction<any>>;
    setFieldValue: FormikProps<any>["setFieldValue"];
    CurrencyHelper?: React.FC<any> | undefined;
    PaymentTypeHelper?: React.FC<any> | undefined;
    PayerAccountHelper?: React.FC<any> | undefined;
    generateType: string;
};

const FormBottom = (props: PropsType) => {
    const {
        s,
        id,
        errors,
        touched,
        values,
        currency,
        paymentType,
        date,
        generateType,
        setDate,
        setCurrency,
        handleChange,
        setPaymentType,
        setFieldValue,
        CurrencyHelper,
        PaymentTypeHelper,
        PayerAccountHelper,
    } = props;

    const [isMobile, setIsMobile] = useState(false);
    const accountsList = useSelector(selectAccountsByFilter);

    const navigate = useNavigate();

    const disabledDate: RangePickerProps["disabledDate"] = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().startOf("day");
    };

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);

    const dateStyle = {
        fontFamily: "Inter",
        fontWeight: 800,
        fontSize: 14,
        lineHeight: "18px",
        color: "#2A2E37",
        padding: "17px 16px",
        height: 52,
        width: isMobile ? "100%" : 311,
        border: "1.5px solid #EEEFEF",
        borderRadius: 8,
    };

    const dateFormat = "YYYY-MM-DD";

    const maxSize = 10 * 1024 * 1024;

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            if (file.size > maxSize) {
                alert(
                    "The file size exceeds the 10MB limit. Please upload a smaller file."
                );
                event.target.value = "";
                return;
            }

            const files = event.currentTarget.files;
            if (files && files.length > 0) {
                // @ts-ignore
                setFieldValue("invoice", files[0]);
            }
        }
    };

    return (
        <>
            {/* {Transfer Details / FormBottom} */}
            <div className={s.block}>
                <div className={s.title}>Transfer Details</div>

                {id !== "create" && (
                    <div className={s.row}>
                        <div className={s.input_block_fixed}>
                            <div className={s.input_label}>
                                * Payment date
                                <Popover
                                    content={
                                        "Please enter the date of the transfer. The date can be 2 years ahead from today."
                                    }
                                    color={"#EEEFEF"}
                                    overlayInnerStyle={{
                                        fontFamily: "Inter",
                                        width: "280px",
                                        padding: "13px 16px",
                                    }}
                                >
                                    <img src={info} alt="" />
                                </Popover>
                            </div>
                            <DatePicker
                                suffixIcon={null}
                                style={dateStyle}
                                defaultValue={dayjs(new Date(), dateFormat)}
                                disabledDate={disabledDate}
                                value={dayjs(date.from)}
                                onChange={(_, dateString) => {
                                    if (!dateString) {
                                        setDate({
                                            ...date,
                                            from: new Date().toDateString(),
                                        });
                                    } else {
                                        setDate({
                                            ...date,
                                            from: dateString,
                                        });
                                    }
                                }}
                                format={dateFormat}
                            />
                        </div>

                        <div className={s.mobile_row}>
                            <div className={classnames(s.input_block, s.short)}>
                                <div className={s.input_label}>
                                    * Amount
                                    <Popover
                                        content={
                                            "Select a currency and enter the transfer amount.\n" +
                                            "In the account balance in the selected currency is insufficient, the system " +
                                            "will automatically offer you to top up using funds in a different currency " +
                                            "you have on the account."
                                        }
                                        color={"#EEEFEF"}
                                        overlayInnerStyle={{
                                            fontFamily: "Inter",
                                            width: "280px",
                                            padding: "13px 16px",
                                        }}
                                    >
                                        <img src={info} alt="" />
                                    </Popover>
                                </div>
                                <MyInput
                                    id={"amount"}
                                    name={"amount"}
                                    touched={touched.amount}
                                    value={values.amount}
                                    onChange={handleChange}
                                    isError={errors.amount}
                                />
                                {errors.amount && touched.amount && (
                                    <div
                                        className={classnames(
                                            s.error_message,
                                            s.error_amount
                                        )}
                                    >
                                        {errors.amount}
                                    </div>
                                )}
                            </div>

                            <div className={s.currency_dropdown}>
                                <div className={s.input_label}>Currency</div>
                                <MyInput
                                    id="transferCurrency"
                                    name="transferCurrency"
                                    value={currency}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className={s.row}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>** Purpose</div>
                        <MyTextarea
                            id={"purpose"}
                            name={"purpose"}
                            onChange={handleChange}
                            value={values.purpose}
                            placeholder={"Enter your purpose of payment"}
                            isError={errors.purpose}
                            touched={touched.purpose}
                        />
                        {errors.purpose && touched.purpose && (
                            <div className={s.error_message}>
                                {errors.purpose}
                            </div>
                        )}
                    </div>
                </div>

                <div className={s.row}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>** reference</div>
                        <MyInput
                            id={"reference"}
                            name={"reference"}
                            touched={touched.reference}
                            value={values.reference}
                            onChange={handleChange}
                            placeholder={"Enter if required by the recipient"}
                            isError={errors.reference}
                        />
                        {errors.reference && touched.reference && (
                            <div className={s.error_message}>
                                {errors.reference}
                            </div>
                        )}
                    </div>
                    <div className={s.input_block}></div>
                </div>

                {values.paymentRegions === "INTERAC (only for CAD)" && (
                    <div className={s.notice}>
                        Maximum Transaction Size: $10000.0
                    </div>
                )}

                {values.paymentRegions === "SWIFT (International Transfer)" && (
                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                payment type
                                <Popover
                                    content={
                                        <div>
                                            <p>
                                                SHA - the payer (you) pays all
                                                the fees applied to the
                                                transfer, and fees applied by
                                                other banks are paid by the
                                                recipient (in some cases the
                                                recipient can simply receive a
                                                smaller amount).
                                            </p>
                                            <p>
                                                OUR - the payer (you) pays all
                                                the fees including the fees of
                                                other banks applied to this
                                                transfer. If the paid fee does
                                                not cover all the fees of a
                                                correspondent bank and the
                                                recipient’s bank, the missing
                                                amount must be paid
                                                additionally. Additional fees of
                                                other banks may also be deducted
                                                from the transferred amount
                                                (frequent cases with transfers
                                                in USD).
                                            </p>
                                            <p>
                                                BEN - the payer (you) does not
                                                pay any fees. The transfer fees
                                                are expected to be covered by
                                                the beneficiary of the funds.
                                                This means that any fees will be
                                                deducted from the amount you are
                                                transferring.
                                            </p>
                                        </div>
                                    }
                                    color={"#EEEFEF"}
                                    overlayInnerStyle={{
                                        fontFamily: "Inter",
                                        width: "280px",
                                        padding: "13px 16px",
                                    }}
                                >
                                    <img src={info} alt="" />
                                </Popover>
                            </div>
                            <div className={s.flex_label}>
                                <label className={s.flex_label}>
                                    <CustomCheckbox
                                        handleChange={() =>
                                            setPaymentType("SHA")
                                        }
                                        id="SHA"
                                        name="SHA"
                                        isChecked={paymentType === "SHA"}
                                    />
                                    <span
                                        className={
                                            paymentType === "SHA"
                                                ? s.type_of_payment_active
                                                : s.type_of_payment_inactive
                                        }
                                    >
                                        SHA
                                    </span>
                                </label>
                                <label className={s.flex_label}>
                                    <CustomCheckbox
                                        handleChange={() =>
                                            setPaymentType("OUR")
                                        }
                                        id="OUR"
                                        name="OUR"
                                        isChecked={paymentType === "OUR"}
                                    />
                                    <span
                                        className={
                                            paymentType === "OUR"
                                                ? s.type_of_payment_active
                                                : s.type_of_payment_inactive
                                        }
                                    >
                                        OUR
                                    </span>
                                </label>
                                <label className={s.flex_label}>
                                    <CustomCheckbox
                                        handleChange={() =>
                                            setPaymentType("BEN")
                                        }
                                        id="BEN"
                                        name="BEN"
                                        isChecked={paymentType === "BEN"}
                                    />
                                    <span
                                        className={
                                            paymentType === "BEN"
                                                ? s.type_of_payment_active
                                                : s.type_of_payment_inactive
                                        }
                                    >
                                        BEN
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {!id && (
                <div className={s.block}>
                    <div className={s.title}>
                        Save the Transfer Details as a Template
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                template Name
                                <Popover
                                    content={
                                        "In order to save this transfer as a template, please enter the " +
                                        "template name."
                                    }
                                    color={"#EEEFEF"}
                                    overlayInnerStyle={{
                                        fontFamily: "Inter",
                                        width: "280px",
                                        padding: "13px 16px",
                                    }}
                                >
                                    <img src={info} alt="" />
                                </Popover>
                            </div>
                            <MyInput
                                id={"templateName"}
                                name={"templateName"}
                                touched={touched.templateName}
                                value={values.templateName}
                                onChange={handleChange}
                            />
                            {errors.templateName && touched.templateName && (
                                <div className={s.error_message}>
                                    {errors.templateName}
                                </div>
                            )}
                        </div>

                        <div className={s.input_block}></div>
                    </div>
                </div>
            )}

            {!id && (
                <div className={s.add_dock_block}>
                    <div className={classnames(s.row, s.flex_start)}>
                        <div className={s.invoice_img}>
                            <img src={invoice} alt="" />
                        </div>
                        <div>
                            <p className={s.invoice_title}>
                                Add an invoice (optional)
                            </p>
                            <p className={s.invoice_desc}>
                                Appropriate format: JPEG, JPG, PNG, TXT, PDF.
                            </p>
                            <p className={s.invoice_desc}>
                                Maximum file size: 10Mb.
                            </p>
                        </div>
                    </div>
                    <div className={s.attached_file_wrapper}>
                        <div className={s.button_wrapper}>
                            <TransparentButton
                                title={"Add file"}
                                small
                                icon={
                                    <Icon
                                        component={() => <Plus />}
                                        rev={undefined}
                                    />
                                }
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    // @ts-ignore
                                    document
                                        .getElementById("hiddenInvoice")
                                        .click();
                                }}
                            />
                            <input
                                type="file"
                                accept=".png, .jpg, .jpeg, .pdf, .txt"
                                id={"hiddenInvoice"}
                                className={s.hidden_input}
                                onChange={handleFileChange}
                            />
                        </div>
                        <div className={s.attached_file}>
                            {values.invoice && <img src={attachedDoc} alt="" />}
                            {
                                // @ts-ignore
                                <span className={s.invoice_doc_name}>
                                    {values?.invoice?.name}
                                </span>
                            }
                            {
                                // @ts-ignore
                                values?.invoice?.name && (
                                    <img
                                        onClick={() => {
                                            // @ts-ignore
                                            const hiddenInvoice =
                                                document.getElementById(
                                                    "hiddenInvoice"
                                                ) as HTMLInputElement;
                                            if (hiddenInvoice) {
                                                hiddenInvoice.value = "";
                                            }
                                            setFieldValue("invoice", "");
                                        }}
                                        className={s.delete_doc}
                                        src={delete_document}
                                        alt=""
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
            )}

            {generateType === "transactionTemplate" ? (
                <div className={s.buttonsWrapper}>
                    <div className={s.btn_block}>
                        <TransparentButton
                            title={"Back"}
                            large
                            icon={
                                <Icon
                                    component={() => <Back />}
                                    rev={undefined}
                                />
                            }
                            onClick={() => {
                                navigate(-1);
                            }}
                        />
                    </div>
                    <MyBtn
                        title={
                            props.id === "create" ? "Save" : "Make a transfer"
                        }
                        large
                        style={{ width: 180 }}
                        type={"submit"}
                        onClick={() => console.log(errors)}
                    />
                </div>
            ) : (
                <div className={s.continue_block}>
                    <div className={s.required}>
                        <div>* required fields.</div>
                        <div>** one of the fields is required.</div>
                    </div>
                    {!id && (
                        <div className={s.button_wrapper}>
                            <MyBtn
                                title={"Continue"}
                                type={"submit"}
                                onClick={() => console.log(errors)}
                            />
                        </div>
                    )}
                </div>
            )}

            <Persist name="transfer-form" />

            {generateType === "personalTransferForm" && (
                <>
                    {CurrencyHelper && (
                        <CurrencyHelper
                            accountsList={accountsList}
                            payerAccount={values.payerAccount}
                            setCurrency={setCurrency}
                        />
                    )}

                    {PaymentTypeHelper && (
                        <PaymentTypeHelper name={"paymentType"} />
                    )}
                    {PayerAccountHelper && (
                        <PayerAccountHelper name={"payerAccount"} />
                    )}
                </>
            )}
        </>
    );
};

export default FormBottom;
