import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import styles from "../../../styles/styles.module.css";
import s from "./Crypto.module.css";
import Wallets from "./Wallets/Wallets";
import Transactions from "./Transactions/Transactions";

const Crypto = () => {

    return (
        <div className={s.container}>
            <Tabs>
                <TabList className={styles.tabstitle_block}>
                    <Tab selectedClassName={styles.active_tab} className={styles.tabtitle}>Wallets</Tab>
                    <Tab selectedClassName={styles.active_tab} className={styles.tabtitle}>Transactions</Tab>
                </TabList>
                <TabPanel>
                    <Wallets />
                </TabPanel>
                <TabPanel>
                    <Transactions />
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default Crypto;
