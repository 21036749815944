import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Layout, Table, Tag } from "antd";
import s from "./Transactions.module.css";
import { RootState, useAppDispatch } from "../../../../store/store";
import { getMercuryoTransactionsThunk } from "../../../../store/reducers/ActionCreators";
import { useAppSelector } from "../../../../hooks/redux";

const Transactions = () => {
    const transactionsData = useSelector(
        (state: RootState) => state.mercuryoReducer.transactionsData
    );

    const dispatch = useAppDispatch();

    const token = useAppSelector((state) => state.userReducer.user.userToken);

    useEffect(() => {
        dispatch(getMercuryoTransactionsThunk(token, { show: true }));
    }, [dispatch, token])

    const columns = [
        {
            title: "Transaction ID",
            dataIndex: "transactionNumber",
            key: "transactionNumber",
            width: 200
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            width: 200
        },
        {
            title: "Currency",
            dataIndex: "currency",
            key: "currency",
            width: 150
        },
        {
            title: "Fiat Amount",
            dataIndex: "fiatAmount",
            key: "fiatAmount",
            width: 150
        },
        {
            title: "Fiat Currency",
            dataIndex: "fiatCurrency",
            key: "fiatCurrency",
            width: 150
        },
        {
            title: "Network",
            dataIndex: "network",
            key: "network",
            width: 150
        },
        {
            title: "Transaction Type",
            dataIndex: "type",
            key: "type",
            render: (value: string) => {
                switch (value) {
                    case "buy":
                        return <Tag color="green">Buy</Tag>
                    case "sell":
                        return <Tag color="red">Sell</Tag>
                    case "withdraw":
                        return <Tag color="blue">Withdraw</Tag>
                    default:
                        return <Tag>No Type</Tag>
                }
            },
            width: 150
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (value: string) => {
                const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);

                return value === "paid" ? (
                    <Tag color="green">{capitalizedValue}</Tag>
                ) : (
                    <Tag color="red">{capitalizedValue}</Tag>
                );
            },
        },
    ];


    const renderTransactions = () => (
        <div className={s.transactionsContainer}>
            <div className={s.transactionsTableWrapper}>
                <div className={s.titleWrapper}>
                    <div>Crypto Transactions</div>
                </div>
                <Layout>
                    <Table
                        bordered={false}
                        className={s.transactionsTable}
                        columns={columns}
                        dataSource={transactionsData}
                        pagination={{
                            pageSize: 5,
                            showTotal: (total, range) =>
                                `${range[0]}-${range[1]} of ${total} items`,
                        }}
                        scroll={{ x: "max-content" }}
                        rowKey={(record: any) => `${record.transactionNumber}-${record.currency}`}
                    />
                </Layout>
            </div>
        </div>
    );

    const noTransactions = () => (
        <div>
            <div className={s.noTransactionWrapper}>
                <div className={s.transactionIcon}></div>
                <p className={s.transactionText}>No Transactions</p>
            </div>
        </div>
    );

    return (
        <React.Fragment>
            {transactionsData.length >= 1
                ? renderTransactions()
                : noTransactions()}
        </React.Fragment>
    );
};

export default Transactions;
