import React, { Dispatch, SetStateAction } from "react";
import { Formik, Form } from "formik";
import { Row, Col, Checkbox } from "antd";
import classnames from "classnames";
import styles from "./KycFormPrincipal.module.css";
import MyInput from "../../ui/MyInput/MyInput";
import SelectWithSearch from "../../ui/SelectWithSearch/SelectWithSearch";
import {
  PrincipalType,
  principalOptions,
  principalMapping,
  IPrincipalData,
  inputFieldDefs,
  documentFieldDefs,
  getPrincipalInitialValuesByType,
} from "../Constants/PrincipalConstants";
import DocumentBlock from "../../DocumentBlock/DocumentBlock";
import MyBtn from "../../ui/MyBtn/MyBtn";
import sampleIcon from "../../../assets/images/personalAccount/registration_sertificate.png";
import CustomCheckbox from "../../ui/customCheckbox/CustomCheckbox";
import PhoneCodesInput from "../../ui/PhoneCodesInput/PhoneCodesInput";

interface IKycFormPrincipalProps {
  initialData: IPrincipalData;
  validationSchema: any;
  index: number;
  hasSignatory: boolean;
  documents: any;
  createModeMissingDoc: string[];
  onSave: (index: number, data: IPrincipalData) => void;
  onSignatoryToggle: (index: number, newVal: boolean) => void;
  onTypeChanged: (index: number, newType: string) => void;
  setDocuments: Dispatch<SetStateAction<any>>;
}

const KycFormPrincipal: React.FC<IKycFormPrincipalProps> = ({
  initialData,
  validationSchema,
  index,
  hasSignatory,
  documents,
  createModeMissingDoc,
  onSave,
  onSignatoryToggle,
  onTypeChanged,
  setDocuments,
}) => {
  const memoizedInitialData = React.useMemo(
    () => initialData,
    // eslint-disable-next-line 
    [initialData.type, initialData.id]
  );

  const availableOptions = React.useMemo(() => {
    if (memoizedInitialData.type === "Signatory") {
      return principalOptions;
    }
    if (hasSignatory) {
      return principalOptions.filter((opt) => opt !== "Signatory");
    }
    return principalOptions;
  }, [hasSignatory, memoizedInitialData.type]);

  return (
    <Formik
      enableReinitialize
      initialValues={memoizedInitialData}
      validationSchema={validationSchema || []}
      onSubmit={(values: IPrincipalData) => {
        onSave(index, values);
      }}
    >
      {({ values, setFieldValue, resetForm, errors, touched }) => {
        if (!values.type) {
          return (
            <Form>
              <Row gutter={24}>
                <Col span={24}>
                  <div className={styles.input_block}>
                    <label className={styles.input_label}>
                      Select Principal Type
                    </label>
                    <SelectWithSearch
                      items={availableOptions}
                      placeholder="Select Principal Type"
                      name="tempType" // temporary field; not saved
                      onChange={(selectedValue: string) => {
                        resetForm({
                          values: getPrincipalInitialValuesByType(selectedValue),
                        });
                        onTypeChanged(index, selectedValue);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          );
        }

        const onSignatoryChange = (checked: boolean) => {
          setFieldValue("isSignatory", checked);
          onSignatoryToggle(index, checked);
        };

        return (
          <Form>
            {/* Signatory Checkbox */}
            {principalMapping[values.type] !== PrincipalType.AGENT && (
              <Row>
                <Col span={24}>
                  <div
                    className={classnames(styles.input_block, styles.isSignatory)}
                  >
                    <label className={styles.input_label}>
                      {values.type === "Signatory" ? (
                        <Checkbox checked={true} disabled={true} />
                      ) : (
                        <Checkbox
                          checked={values.isSignatory}
                          onChange={(e) => onSignatoryChange(e.target.checked)}
                          disabled={hasSignatory && !values.isSignatory}
                        />
                      )}
                      &nbsp;Is Signatory?
                    </label>
                  </div>
                </Col>
              </Row>
            )}

            {/* Principal Type Dropdown (always visible) */}
            <Row gutter={24}>
              <Col span={24}>
                <div className={styles.input_block}>
                  <label className={styles.input_label}>
                    Select Principal Type
                  </label>
                  <SelectWithSearch
                    items={availableOptions}
                    placeholder="Select Principal Type"
                    name="type"
                    onChange={(selectedValue: string) => {
                      resetForm({
                        values: getPrincipalInitialValuesByType(selectedValue),
                      });
                      onTypeChanged(index, selectedValue);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              {inputFieldDefs.map((field: any) => {
                const isFieldRequired =
                  typeof field.required === "function"
                    ? field.required(values.type)
                    : field.required;
                return (
                  (typeof field.show === "function"
                    ? field.show(values.type)
                    : field.show) && (
                    <Col xs={24} sm={12} key={field.name}>
                      <div className={styles.input_block}>
                        <label className={styles.input_label}>
                          {isFieldRequired ? `* ${field.label}` : field.label}
                        </label>
                        {(field.type === "text" ||
                          field.type === "email" ||
                          field.type === "website") && (
                          <MyInput
                            id={field.name}
                            name={field.name}
                            value={(values as any)[field.name]}
                            onChange={async (e) => {
                              await setFieldValue(field.name, e.target.value);
                            }}
                            touched={touched}
                            isError={
                              !!(errors as any)[field.name] &&
                              (touched as any)[field.name]
                            }
                          />
                        )}
                        {field.type === "search" && (
                          <SelectWithSearch
                            id={field.name}
                            name={field.name}
                            touched={touched}
                            isError={
                              !!(errors as any)[field.name] &&
                              (touched as any)[field.name]
                            }
                            items={field.data as any}
                          />
                        )}
                        {field.type === "phone" && (
                          <PhoneCodesInput
                            id={field.name}
                            name={field.name}
                            value={(values as any)[field.name]}
                            onChange={(value: string) =>
                              setFieldValue(field.name, value)
                            }
                            error={
                              !!(errors as any)[field.name] &&
                              (touched as any)[field.name]
                            }
                            touched={(touched as any)[field.name]}
                          />
                        )}
                        {field.type === "radio" && (
                          <div
                            className={classnames(
                              styles.flex_label,
                              styles.radio_block
                            )}
                          >
                            <label className={styles.flex_label}>
                              <CustomCheckbox
                                handleChange={() => {
                                  setFieldValue(field.name, false);
                                }}
                                id={`${field.name}-No`}
                                name={`${field.name}-No`}
                                isChecked={(values as any)[field.name] === false}
                              />
                              <span className={styles.type_of_account}>
                                No
                              </span>
                            </label>
                            <label className={styles.flex_label}>
                              <CustomCheckbox
                                handleChange={() => {
                                  setFieldValue(field.name, true);
                                }}
                                id={`${field.name}-Yes`}
                                name={`${field.name}-Yes`}
                                isChecked={(values as any)[field.name] === true}
                              />
                              <span className={styles.type_of_account}>
                                Yes
                              </span>
                            </label>
                          </div>
                        )}
                        {(errors as any)[field.name] &&
                          (touched as any)[field.name] && (
                            <div className={styles.error_message}>
                              {(errors as any)[field.name]}
                            </div>
                          )}
                      </div>
                    </Col>
                  )
                );
              })}
            </Row>

            {documentFieldDefs.map((doc) => {
              const showDoc =
                typeof doc.show === "function"
                  ? doc.show(values.type)
                  : doc.show;
              return (
                showDoc && (
                  <DocumentBlock
                    key={`${index}-${doc.name}`}
                    uniqueId={`${index}-${doc.name}`}
                    title={doc.label}
                    documentType={doc.name}
                    isRequired={
                      typeof doc.required === "function"
                        ? doc.required(values.type)
                        : doc.required
                    }
                    iconUrl={sampleIcon}
                    createModeMissingDoc={createModeMissingDoc}
                    editModeMissingDoc={[]}
                    documents={documents}
                    setDocuments={setDocuments}
                    maxFileSize={10}
                    allowedFileTypes={["application/pdf"]}
                    uploadedFiles={initialData.files || {}}
                  />
                )
              );
            })}

            <MyBtn title={`Save Principal #${index + 1}`} medium type="submit" />
          </Form>
        );
      }}
    </Formik>
  );
};

export default KycFormPrincipal;
