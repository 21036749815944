import * as yup from "yup";
import { countries } from "../../../constants/StaticData";

export enum PrincipalType {
    SHAREHOLDERS = 1,
    DIRECTORS = 2,
    ULTIMATE_BENEFICIAL_OWNER = 3,
    COMPLIANCE_OFFICER = 4,
    AGENT = 5,
    SIGNATORY = 6,
}

const principalTypeOptions = [
    { label: "Shareholders", value: PrincipalType.SHAREHOLDERS },
    { label: "Directors", value: PrincipalType.DIRECTORS },
    {
        label: "Ultimate Beneficial Owner",
        value: PrincipalType.ULTIMATE_BENEFICIAL_OWNER,
    },
    { label: "Compliance Officer", value: PrincipalType.COMPLIANCE_OFFICER },
    { label: "Agent", value: PrincipalType.AGENT },
    { label: "Signatory", value: PrincipalType.SIGNATORY },
];

const principalOptions = [
    "Shareholders",
    "Directors",
    "Ultimate Beneficial Owner",
    "Compliance Officer",
    "Agent",
    "Signatory",
];

const principalMapping: Record<string, number> = {
    Shareholders: PrincipalType.SHAREHOLDERS,
    Directors: PrincipalType.DIRECTORS,
    "Ultimate Beneficial Owner": PrincipalType.ULTIMATE_BENEFICIAL_OWNER,
    "Compliance Officer": PrincipalType.COMPLIANCE_OFFICER,
    Agent: PrincipalType.AGENT,
    Signatory: PrincipalType.SIGNATORY,
};

interface IPrincipalData {
    id: number;
    type: any;
    isSaved: boolean;
    isSignatory: boolean;
    firstName: string;
    lastName: string;
    country: string;
    // Common fields (for non‑agent types, required; for agent, optional)
    shareholding: string;
    passport: string; // e.g. Passport/ID number
    address: string;
    phoneNumber: string;
    emailAddress: string;
    website: string;
    isPep: boolean;
    sanctionsStatus: string;
    // Document uploads for common fields
    passportFront: File | null;
    passportBack: File | null;
    proofOfAddress: File | null;
    proofOfSourceOfWealth: File | null;
    cv: File | null;
    files: any;
    // Agent‑specific fields
    nameOfEntity: string;
    registrationCertificate: File | null;
}

const inputFieldDefs = [
    {
        name: "firstName",
        label: "First Name",
        required: true,
        type: "text",
        show: (type: string) => principalMapping[type] !== PrincipalType.AGENT,
    },
    {
        name: "lastName",
        label: "Last Name",
        required: true,
        type: "text",
        show: (type: string) => principalMapping[type] !== PrincipalType.AGENT,
    },
    {
        name: "country",
        label: "Country",
        required: true,
        type: "search",
        data: countries,
        show: (type: string) => principalMapping[type] !== PrincipalType.AGENT,
    },
    {
        name: "address",
        label: "Address",
        required: true,
        type: "text",
        show: (type: string) => principalMapping[type] !== PrincipalType.AGENT,
    },
    {
        name: "nameOfEntity",
        label: "Name of Entity",
        required: (type: string) =>
            principalMapping[type] === PrincipalType.AGENT,
        show: (type: string) => principalMapping[type] === PrincipalType.AGENT,
        type: "text",
    },
    {
        name: "shareholding",
        label: "Shareholding",
        required: (type: string) =>
            principalMapping[type] !== PrincipalType.AGENT,
        show: (type: string) => principalMapping[type] !== PrincipalType.AGENT,
        type: "text",
    },
    {
        name: "passport",
        label: "Passport/ID Number",
        required: (type: string) =>
            principalMapping[type] !== PrincipalType.AGENT,
        show: (type: string) => principalMapping[type] !== PrincipalType.AGENT,
        type: "text",
    },
    {
        name: "phoneNumber",
        label: "Phone Number",
        required: (type: string) =>
            principalMapping[type] !== PrincipalType.AGENT,
        show: (type: string) => principalMapping[type] !== PrincipalType.AGENT,
        type: "phone",
    },
    {
        name: "emailAddress",
        label: "Email",
        required: (type: string) =>
            principalMapping[type] !== PrincipalType.AGENT,
        show: (type: string) => principalMapping[type] !== PrincipalType.AGENT,
        type: "email",
    },
    {
        name: "website",
        label: "Website",
        required: (type: string) =>
            principalMapping[type] !== PrincipalType.AGENT,
        show: (type: string) => principalMapping[type] !== PrincipalType.AGENT,
        type: "website",
    },
    {
        name: "sanctionsStatus",
        label: "Sanctions Status",
        required: (type: string) =>
            principalMapping[type] !== PrincipalType.AGENT,
        show: (type: string) => principalMapping[type] !== PrincipalType.AGENT,
        type: "text",
    },
    {
        name: "isPep",
        label: "Politically exposed person (pep)",
        required: (type: string) =>
            principalMapping[type] !== PrincipalType.AGENT,
        show: (type: string) => principalMapping[type] !== PrincipalType.AGENT,
        type: "radio",
    },
];

const documentFieldDefs = [
    {
        name: "passportFront",
        label: "Passport / ID (Front)",
        required: (type: string) =>
            principalMapping[type] !== PrincipalType.AGENT,
        show: (type: string) => principalMapping[type] !== PrincipalType.AGENT,
    },
    {
        name: "passportBack",
        label: "Passport / ID (Back)",
        required: (type: string) =>
            principalMapping[type] !== PrincipalType.AGENT,
        show: (type: string) => principalMapping[type] !== PrincipalType.AGENT,
    },
    {
        name: "proofOfAddress",
        label: "Proof of Address",
        required: (type: string) =>
            principalMapping[type] !== PrincipalType.AGENT,
        show: (type: string) => principalMapping[type] !== PrincipalType.AGENT,
    },
    {
        name: "proofOfSourceOfWealth",
        label: "Proof of Source of Wealth",
        required: (type: string) =>
            principalMapping[type] !== PrincipalType.AGENT,
        show: (type: string) => principalMapping[type] !== PrincipalType.AGENT,
    },
    {
        name: "cv",
        label: "CV (Resume)",
        required: (type: string) =>
            principalMapping[type] !== PrincipalType.AGENT,
        show: (type: string) => principalMapping[type] !== PrincipalType.AGENT,
    },
    {
        name: "registrationCertificate",
        label: "Registration Certificate",
        required: (type: string) =>
            principalMapping[type] === PrincipalType.AGENT,
        show: (type: string) => principalMapping[type] === PrincipalType.AGENT,
    },
];

const getPrincipalInitialValuesByType = (selectedType: string): any => {
    const initialValues: Record<string, any> = {};
    if (selectedType === "default") {
        initialValues.type = "";
    } else {
        inputFieldDefs.forEach((field) => {
            const shouldShow =
                typeof field.show === "function"
                    ? field.show(selectedType)
                    : true;
            if (shouldShow) {
                initialValues[field.name] = "";
            }
        });
    }
    return initialValues as Partial<IPrincipalData>;
};

const getPrincipalValidationSchemaByType = (selectedType: string) => {
    const shape: { [key: string]: any } = {};
    inputFieldDefs.forEach((field) => {
        const shouldShow =
            typeof field.show === "function" ? field.show(selectedType) : true;
        if (shouldShow) {
            const isRequired =
                typeof field.required === "function"
                    ? field.required(selectedType)
                    : field.required;

            let validator = yup.string();

            switch (field.type) {
                case "email":
                    // eslint-disable-next-line no-useless-escape
                    validator = validator.matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        "Invalid email format"
                    );
                    break;

                case "phone":
                    // eslint-disable-next-line no-useless-escape
                    validator = validator.matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){0,20}(\s*)?$/,
                            "Must be only digits"
                        )
                        .min(6, "Min 6 characters")
                        .max(20, "Max 20 characters");
                    break;

                case "website":
                    // eslint-disable-next-line no-useless-escape
                    validator = validator.matches(/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
                        "Invalid website URL"
                    );
                    break;

                default:
                    break;
            }

            // Add required validation if needed
            if (isRequired) {
                validator = validator.required(`${field.label} is required`);
            }

            shape[field.name] = validator;
        }
    });

    return yup.object().shape(shape);
};

const requiredDocuments = {
    Shareholders: [
        "passportFront",
        "passportBack",
        "proofOfAddress",
        "proofOfSourceOfWealth",
        "cv",
    ],
    Directors: [
        "passportFront",
        "passportBack",
        "proofOfAddress",
        "proofOfSourceOfWealth",
        "cv",
    ],
    "Ultimate Beneficial Owner": [
        "passportFront",
        "passportBack",
        "proofOfAddress",
        "proofOfSourceOfWealth",
        "cv",
    ],
    "Compliance Officer": [
        "passportFront",
        "passportBack",
        "proofOfAddress",
        "proofOfSourceOfWealth",
        "cv",
    ],
    Agent: ["registrationCertificate"],
    Signatory: [
        "passportFront",
        "passportBack",
        "proofOfAddress",
        "proofOfSourceOfWealth",
        "cv",
    ],
};

const defaultDocuments = {
    Shareholders: {
        passportFront: "",
        passportBack: "",
        proofOfAddress: "",
        proofOfSourceOfWealth: "",
        cv: "",
    },
    Directors: {
        passportFront: "",
        passportBack: "",
        proofOfAddress: "",
        proofOfSourceOfWealth: "",
        cv: "",
    },
    "Ultimate Beneficial Owner": {
        passportFront: "",
        passportBack: "",
        proofOfAddress: "",
        proofOfSourceOfWealth: "",
        cv: "",
    },
    "Compliance Officer": {
        passportFront: "",
        passportBack: "",
        proofOfAddress: "",
        proofOfSourceOfWealth: "",
        cv: "",
    },
    Agent: {
        registrationCertificate: "",
    },
    Signatory: {
        passportFront: "",
        passportBack: "",
        proofOfAddress: "",
        proofOfSourceOfWealth: "",
        cv: "",
    },
};

export {
    getPrincipalInitialValuesByType,
    getPrincipalValidationSchemaByType,
    defaultDocuments,
    requiredDocuments,
    inputFieldDefs,
    documentFieldDefs,
    principalOptions,
    principalMapping,
    principalTypeOptions,
};

export type { IPrincipalData };
