import React, { useEffect, useState } from "react";
import KycPersonalStepOne from "./KycPersonalStepOne/KycPersonalStepOne";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";
import { getPhoneListThunk } from "../../../../../store/reducers/ActionCreators";
import GenerateKycForm from "../../../../../components/GenerateKycForm/GenerateKycForm";
import s from "./KycPersonal.module.css"
import { Col, Row } from "antd";
const KycPersonal = () => {
    const [current, setCurrent] = useState(0);
    const token = useAppSelector((state) => state.userReducer.user.userToken);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getPhoneListThunk(token));
    }, [dispatch, token]);

    const steps = [
        {
            content: (
                <KycPersonalStepOne setCurrent={setCurrent} current={current} />
            ),
        },
        {
            content: (
                <GenerateKycForm
                    type="personal"
                    action="create"
                    current={current}
                    setCurrent={setCurrent}
                    title={"KYC for Personal Account"}
                />
            ),
        },
        {
            content: (
                <GenerateKycForm
                    type="personal"
                    action="edit"
                    current={current}
                    setCurrent={setCurrent}
                    title={"Edit KYC Personal Account"}
                />
            ),
        },
    ];

    return (current === 0 ? <div>{steps[current].content}</div> :
        <Row justify="start">
            <Col span={24} xl={18} className={s.kycPersonalWrapper}>
                {steps[current].content}
            </Col>
        </Row>
    )
};

export default KycPersonal;
