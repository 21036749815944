import React, { Dispatch, SetStateAction, useState } from 'react'
import CustomModalTabList from '../../../../../components/ui/CustomModalTabList/CustomModalTabList';
import { useAppSelector } from '../../../../../hooks/redux';
import { appConfig } from '../../../../../config/config';
import { cryptoShortName } from '../../../../../constants/StaticData';
import { useAppDispatch } from '../../../../../store/store';
import { createMercuryoTransactionThunk, mercuryoRegisterThunk } from '../../../../../store/reducers/ActionCreators';
import { Loader } from '../../../../../components/Loader/Loader';
import MyBtn from '../../../../../components/ui/MyBtn/MyBtn';

type PropsType = {
    s: any;
    token: any;
    isLoading: any;
    selectedWallet: any;
    widgetCredentials: any;
    isModalOpen: any;
    setIsModalOpen: Dispatch<SetStateAction<any>>;
    setSuccessMessage: Dispatch<SetStateAction<any>>;
    setIsSuccessModalOpen: Dispatch<SetStateAction<any>>;
    setErrorMessage: Dispatch<SetStateAction<any>>;
    setIsErrorModalOpen: Dispatch<SetStateAction<any>>;
}

const TransactionByCardModal = (props: PropsType) => {

    const {
        s,
        token,
        isLoading,
        selectedWallet,
        widgetCredentials,
        isModalOpen,
        setIsModalOpen,
        setSuccessMessage,
        setIsSuccessModalOpen,
        setErrorMessage,
        setIsErrorModalOpen
    } = props;

    const dispatch = useAppDispatch();

    const cryptoEnabled = useAppSelector((state) => state.mercuryoReducer.cryptoEnabled);
    const [sellWalletAddress, setSellWalletAddress] = useState(null);
    const [modalDismissAllowed, setModalDismissAllowed] = useState(true);
    const [lastRequestID, setLastRequestID] = useState(null);

    const enableCrypto = () => (
        <div className={s.noTransactionWrapper}>
            {isLoading ? <Loader /> :

                (<React.Fragment>
                    <p className={s.transactionText}>
                        <b>In order to make crypto payments, enable this feature</b>
                        <br />
                        <br />
                        By enabling this feature you agree to{" "}
                        <b>
                            <a
                                rel="noreferrer"
                                href="https://mercuryo.io/legal/terms/"
                                target="_blank"
                            >
                                Terms of Service
                            </a>
                        </b>
                    </p>
                    <div className={s.buttonWrapper}>
                        <MyBtn
                            title={"Enable Crypto"}
                            large
                            onClick={handleEnableCrypto}
                            type={"button"}
                        />
                    </div>
                </React.Fragment>
                )
            }
        </div>
    );

    const handleEnableCrypto = async () => {
        try {
            await dispatch(mercuryoRegisterThunk(token)).then((res: any) => {
                if(res?.status === 201 || res?.status === 200) {
                    setSuccessMessage("Successfully enabled!");
                    setIsSuccessModalOpen(true);
                }
            }).catch((error) => {
                setErrorMessage(error?.response?.data || error?.message || "An error occured, please contact support!");
                setIsErrorModalOpen(true);
            }).finally(() => setIsModalOpen(false));
        } catch (error) {
            console.error("Error enabling crypto:", error);
        }
    };

    return (
        <CustomModalTabList
            isModalOpen={isModalOpen}
            tabs={[
                {
                    label: "Buy",
                    action: async () => {
                        if (cryptoEnabled) {
                            try {
                                // @ts-ignore
                                await mercuryoWidget.run({
                                    widgetId: appConfig.mercuryoWidgetId,
                                    host: document.getElementById(
                                        "mercuryo-widget-buy"
                                    ),
                                    signature: widgetCredentials.signature,
                                    initToken:
                                        widgetCredentials.init_token,
                                    initTokenType:
                                        widgetCredentials.init_type_token,
                                    type: "buy",
                                    currency: cryptoShortName[selectedWallet?.currency],
                                    network: selectedWallet.network,
                                    fixCurrency: true,
                                    address: selectedWallet.address,
                                    onStatusChange: async (data: any) => {
                                        console.log("status changed: ", data);
                                        if (data.status === "pending") {
                                            setModalDismissAllowed(false);
                                        } else if (data.status === "paid") {
                                            setModalDismissAllowed(true);

                                            const finalData = {
                                                type: "buy",
                                                ...data
                                            }

                                            await dispatch(
                                                createMercuryoTransactionThunk(
                                                    token,
                                                    finalData
                                                )
                                            ).then((res) => {
                                                if (res.data === 201) {
                                                    setIsModalOpen(false);
                                                    setSuccessMessage("Transaction has been successfully made");
                                                    setIsSuccessModalOpen(true);
                                                } else {
                                                    setIsModalOpen(false);
                                                    setErrorMessage("Opps something went wrong, please try again!");
                                                    setIsErrorModalOpen(true);
                                                }
                                            });
                                        }
                                    },
                                });
                            } catch (error) {
                                console.error(
                                    "Error running Mercuryo widget:",
                                    error
                                );
                            }
                        }
                    },
                },
                {
                    label: "Sell",
                    action: async () => {
                        if (cryptoEnabled) {
                            try {
                                // @ts-ignore
                                await mercuryoWidget.run({
                                    widgetId: appConfig.mercuryoWidgetId,
                                    host: document.getElementById(
                                        "mercuryo-widget-sell"
                                    ),
                                    signature: widgetCredentials.signature,
                                    initToken:
                                        widgetCredentials.init_token,
                                    initTokenType:
                                        widgetCredentials.init_type_token,
                                    type: "sell",
                                    currency: cryptoShortName[selectedWallet?.currency],
                                    network: selectedWallet.network,
                                    fixCurrency: true,
                                    hideRefundAddress: true, // Sell Transaction Only
                                    refundAddress: selectedWallet.address, // Sell Transaction Only
                                    address: selectedWallet.address,
                                    onSellTransferEnabled: async (data: any) => setSellWalletAddress(data.address),
                                    onStatusChange: async (data: any) => {
                                        console.log("status changed: ", data);
                                        if (data.status === "pending") {
                                            setModalDismissAllowed(false);
                                        } else if (data.status === "new" && String(lastRequestID) !== String(data.id) && sellWalletAddress) {
                                            setLastRequestID(data.id);
                                            setModalDismissAllowed(true);
                                            const finalData = {
                                                type: "sell",
                                                address: sellWalletAddress,
                                                ...data,
                                            }
                                            await dispatch(
                                                createMercuryoTransactionThunk(
                                                    token,
                                                    finalData
                                                )
                                            ).then((res) => {
                                                if (res.data === 201) {
                                                    setIsModalOpen(false);
                                                    setSuccessMessage("Transaction has been successfully made");
                                                    setIsSuccessModalOpen(true);
                                                } else {
                                                    setIsModalOpen(false);
                                                    setErrorMessage("Opps something went wrong, please try again!");
                                                    setIsErrorModalOpen(true);
                                                }
                                            });
                                        } else if (data.status === "succeeded") {
                                            setLastRequestID(data.id);
                                            setModalDismissAllowed(true);
                                            const finalData = {
                                                ...data,
                                                address: sellWalletAddress
                                            }
                                            await dispatch(
                                                createMercuryoTransactionThunk(
                                                    token,
                                                    finalData
                                                )
                                            ).then((res) => {
                                                if (res.data === 201) {
                                                    setIsModalOpen(false);
                                                    setSuccessMessage("Transaction has been successfully made");
                                                    setIsSuccessModalOpen(true);
                                                } else {
                                                    setIsModalOpen(false);
                                                    setErrorMessage("Opps something went wrong, please try again!");
                                                    setIsErrorModalOpen(true);
                                                }
                                            });
                                        }
                                    },
                                });
                            } catch (error) {
                                console.error(
                                    "Error running Mercuryo widget:",
                                    error
                                );
                            }
                        }
                    },
                },
            ]}
            tabContents={{
                Buy: (cryptoEnabled ?
                    <div
                        id="mercuryo-widget-buy"
                        style={{ height: "500px" }}
                    /> : enableCrypto()
                ),
                Sell: (cryptoEnabled ?
                    <div
                        id="mercuryo-widget-sell"
                        style={{ height: "500px" }}
                    /> : enableCrypto()
                ),
            }}
            onClose={() => modalDismissAllowed && setIsModalOpen(false)}
        />
    )
}

export default TransactionByCardModal;