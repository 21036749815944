import React, { useState } from "react";
import KycBusinessStepOne from "./KycBusinessStepOne/KycBusinessStepOne";
import GenerateKycForm from "../../../../../components/GenerateKycForm/GenerateKycForm";
import { Col, Row, Steps } from "antd";
import s from "./KycBusiness.module.css";
import { useAppSelector } from "../../../../../hooks/redux";
import GeneratePrincipalForm from "../../../../../components/GeneratePrincipalForm/GeneratePrincipalForm";
import KycConfirmationStep from "./KycConfirmationStep.tsx/KycConfirmationStep";

const KycBusiness = () => {
    const [current, setCurrent] = useState(0);
    const kycList = useAppSelector(state => state.userReducer.user.kycList.data)
    const isEditingData = kycList?.status;

    const steps = [
        {
            content: (
                <KycBusinessStepOne setCurrent={setCurrent} current={current} />
            ),
        },
        {
            content: (
                <GenerateKycForm
                    type="business"
                    action={isEditingData ? "edit" : "create"}
                    current={current}
                    setCurrent={setCurrent}
                />
            ),
        },
        {
            content: (
                <GeneratePrincipalForm
                    current={current}
                    setCurrent={setCurrent}
                />
            ),
        },
        {
            content: (
                <KycConfirmationStep />
            ),
        },
    ];
    return (current === 0 ? <div>{steps[current].content}</div> :
        <Row justify="start" className={s.myStepsRow}>
            <Col span={24} xl={18} className={s.myStepsContainer}>
                <div className={s.myStepsContainer}>
                    <div className={s.title}>{isEditingData ? "Editing" : ""} KYC for Business Account</div>
                    <Steps
                        size="default"
                        current={current - 1}
                        items={[
                            { title: "Company Details" },
                            { title: "Principal Officers" },
                            { title: "Confirmation" },
                        ]}
                    />
                    <div className={s.myStepsContent}>
                        {steps[current].content}
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default KycBusiness;
