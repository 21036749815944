import React, { Dispatch, SetStateAction, useEffect } from 'react';
import s from "./KycBusinessStepOne.module.css";
import personalKyc from "../../../../../../assets/images/personalAccount/personal_kyc.png";
import MyBtn from "../../../../../../components/ui/MyBtn/MyBtn";
import classnames from "classnames";
import { motion } from 'framer-motion';
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/redux";
import { getKycListThunk } from "../../../../../../store/reducers/ActionCreators";

type PropsType = {
    current: any;
    setCurrent: Dispatch<SetStateAction<number>>;
};

const KycBusinessStepOne = (props: PropsType) => {
    const dispatch = useAppDispatch();
    const token = useAppSelector(state => state.userReducer.user.userToken);
    const kycData: any = useAppSelector(state => state.userReducer.user.kycList.data);

    useEffect(() => {
        dispatch(getKycListThunk(token));
    }, [dispatch, token]);

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
            {!kycData?.status ? (
                <div className={s.verification_block}>
                    <img src={personalKyc} alt="Personal KYC" />
                    <div className={s.title}>KYC for Business Account</div>
                    <div className={s.button_wrapper}>
                        <MyBtn title="Verification" onClick={() => props.setCurrent(1)} />
                    </div>
                </div>
            ) : (
                <div className={s.questionnaire_list_block}>
                    <div className={s.questionnaire_title}>Questionnaire List</div>
                    <div className={s.questionnaire_table}>
                        <div className={s.table_header}>
                            <div className={s.header_value}>Owner</div>
                            <div className={s.header_value}>State</div>
                            {!["Confirmed", "Pending"].includes(kycData.status) && (
                                <div className={s.header_value}>Action</div>
                            )}
                        </div>
                        <div className={s.table_body}>
                            <div key={kycData.id} className={s.table_row}>
                                <div className={s.row_value}>
                                    <div className={s.name}>{kycData.owner}</div>
                                </div>
                                <div className={s.row_value}>
                                    <div className={s[kycData?.status?.toLowerCase()]}>{kycData.status}</div>
                                </div>
                                {["Rejected", "Incomplete"].includes(kycData.status) && (
                                    <div className={classnames(s.row_value, s.flex, s.gap)}>
                                        <div className={classnames(s.flex, s.action)} onClick={() => props.setCurrent((kycData.status === "Incomplete" && 2) || 1)}>
                                            <div className={s.edit_active}></div>
                                            <div className={s.edit_text_active}>
                                                {kycData.status === "Rejected" ? "Edit" : "Continue"}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </motion.div>
    );
};

export default KycBusinessStepOne;