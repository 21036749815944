import React, { useState } from 'react';
import ExchangeStepOne from "./ExchangeStepOne/ExchangeStepOne";
import ExchangeStepTwo from "./ExchangeStepTwo/ExchangeStepTwo";

type PropsType = {
    transactionType?: "buy" | "sell";
    usdtAccounts?: any;
}

const CurrencyExchange = (props: PropsType) => {

    const [current, setCurrent] = useState(0);
    const [exchangeData, setExchangeData] = useState([])

    const steps = [
        {
            content: <ExchangeStepOne setCurrent={setCurrent}
                current={current}
                setExchangeData={setExchangeData}
                transactionType={props.transactionType}
                usdtAccounts={props.usdtAccounts}

            />
        },
        {
            content: <ExchangeStepTwo setCurrent={setCurrent}
                current={current}
                exchangeData={exchangeData}
            />
        }
    ]

    return (
        <div>
            {steps[current].content}
        </div>
    )
};

export default CurrencyExchange;
