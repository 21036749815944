import React from "react";
import { Row, Col, Typography, Spin } from "antd";
import s from "./KycConfirmationStep.module.css";
import brandConfig from "../../../../../../config/config";


const { Title, Paragraph } = Typography;

const KycConfirmationStep: React.FC = () => {
  return (
    <Row justify="center" align="middle" className={s.confirmationContainer}>
      <Col span={24} className={s.confirmationContent}>
        <Spin size="large" spinning={false} className={s.confirmationSpinner} />

        <Title level={3} className={s.confirmationTitle}>
          Your KYC Application is Pending Approval
        </Title>

        <Paragraph className={s.confirmationDescription}>
          Thank you for submitting your KYC details. Your application is currently under review. We will notify you via email once the verification process is complete.
        </Paragraph>

        <Paragraph className={s.confirmationNote}>
          If you have any questions or need further assistance, please contact our support team at&nbsp;
          <a href={`mailto:${brandConfig.companySupportEmailAddress}`} className={s.supportLink}>
            {brandConfig.companySupportEmailAddress}.
          </a>
        </Paragraph>
      </Col>
    </Row>
  );
};

export default KycConfirmationStep;