import React from 'react';
import s from './MyBtn.module.css'
import classNames from "classnames";
import {useAppSelector} from "../../../hooks/redux";

type PropsType = {
  title: string,
  onClick?: () => void;
  type?: 'submit' | 'button' | 'reset';
  style?: React.CSSProperties;
  icon?: string;
  large?: boolean;
  medium?: boolean;
  small?: boolean;
  buttonType?: 'confirm' | 'error';
  localDisable?: boolean
}

const MyBtn: React.FC<PropsType> = ({type, title, icon, large, medium, small, buttonType,localDisable, ...props}) => {

    const {disabled} = useAppSelector(store => store.userReducer)

  return (
    <button className={classNames(s.my_btn, {
      [s.large]: large,
      [s.medium]: medium,
      [s.small]: small,
      [s.disabled]: (disabled ? disabled : localDisable),
      [s.confirm]: buttonType === 'confirm',
      [s.error]: buttonType === 'error',
    })}
            disabled={disabled ? disabled : localDisable}
            {...props} type={type}>
      {
        icon && <img className={s.img} src={icon} alt=""/>
      }
      {title}
    </button>
  );
};

export default MyBtn;
