import React, { Dispatch, SetStateAction } from "react";
import FileInput from "../FileInput/FileInput";
import classnames from "classnames";
import type { FileTypes } from "../GenerateKycForm/Constants/KycConstants";
import s from "./DocumentBlock.module.css";

type PropsType = {
  uniqueId?: string;
  kycData?: any;
  documentType: string;
  title: string;
  iconUrl: string;
  createModeMissingDoc: string[];
  editModeMissingDoc: string[];
  descriptionItems?: string[] | any;
  descriptionText?: string;
  documents: any;
  allowedFileTypes: FileTypes[];
  maxFileSize: number;
  isRequired: boolean;
  note?: string;
  setDocuments: Dispatch<SetStateAction<any>>;
  uploadedFiles?: Record<string, boolean | string>;
};

const DocumentBlock = (props: PropsType) => {
  const {
    uniqueId,
    kycData,
    uploadedFiles,
    documentType,
    title,
    iconUrl,
    createModeMissingDoc,
    editModeMissingDoc,
    descriptionItems,
    descriptionText,
    documents,
    allowedFileTypes,
    maxFileSize,
    isRequired,
    note,
    setDocuments,
  } = props;

  return (
    <React.Fragment>
      <div className={s.add_doc_block}>
        <div className={s.content_block}>
          <div>
            <img src={iconUrl} alt="Document Icon" />
          </div>
          <div>
            <div
              className={classnames(s.title, {
                [s.doc_error]:
                  isRequired &&
                  !documents[documentType] &&
                  (createModeMissingDoc.includes(documentType) ||
                    editModeMissingDoc.includes(documentType)),
              })}
            >
              {isRequired && <span className={s.required}>* </span>}
              {title}
            </div>
            {descriptionText && (
              <div className={s.add_block_text}>{descriptionText}</div>
            )}
            <ul className={s.passport_spread_list}>
              {descriptionItems &&
                descriptionItems.map((item: string, index: number) => (
                  <li key={index}>
                    <span className={s.text_color}>{item}</span>
                  </li>
                ))}
              <li>
                <span className={s.text_color}>
                  Allowed file types:&nbsp;
                  {allowedFileTypes
                    .map((type) => type.replace(".", "").toUpperCase())
                    .join(", ")}
                  .
                </span>
              </li>
              <li>
                <span className={s.text_color}>
                  Maximum file size: {maxFileSize}Mb.
                </span>
              </li>
              {note && <div className={s.attention}>{note}</div>}
            </ul>
          </div>
        </div>

        <FileInput
          documents={documents}
          key={uniqueId ? `${uniqueId}-${documentType}` : documentType}
          inputId={uniqueId ? `${uniqueId}-${documentType}` : documentType}
          allowedTypes={allowedFileTypes}
          identifier={documentType}
          maxFileSize={maxFileSize}
          kycData={kycData && kycData}
          uploadedFiles={uploadedFiles}
          onChange={(event) => {
            setDocuments({
              ...documents,
              // @ts-ignore
              [documentType]: event.currentTarget.files[0],
            });
          }}
          onImageClick={() => {
            const fileInput = uniqueId
              ? document.getElementById(`${uniqueId}-${documentType}`)
              : document.getElementById(documentType);
            // @ts-ignore
            if (fileInput) fileInput.value = "";
            setDocuments({ ...documents, [documentType]: "" });
          }}
        />
      </div>
      <div className={s.line}></div>
    </React.Fragment>
  );
};

export default DocumentBlock;
