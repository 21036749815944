import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import s from "./KycPersonalStepOne.module.css";
import personalKyc from "../../../../../../assets/images/personalAccount/personal_kyc.png";
import MyBtn from "../../../../../../components/ui/MyBtn/MyBtn";
import classnames from "classnames";
import { getKycListThunk, getSumSubStatementThunk } from "../../../../../../store/reducers/ActionCreators";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/redux";
import { motion } from 'framer-motion';
import sumsubWebSdk from "@sumsub/websdk";

type PropsType = {
    current: number;
    setCurrent: Dispatch<SetStateAction<number>>;
};

const KycPersonalStepOne = (props: PropsType) => {
    const dispatch = useAppDispatch();
    const token = useAppSelector(state => state.userReducer.user.userToken);
    const newKycList: any = useAppSelector(state => state.userReducer.user.kycList.data);
    const userData = useAppSelector(state => state.userReducer.user.userData);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowSumSub, setIsShowSumSub] = useState(false);

    useEffect(() => {
        dispatch(getKycListThunk(token))
    }, [dispatch, token])

    const startSumsubVerification = async () => {
        setIsLoading(true);
        setIsShowSumSub(true);
        dispatch(getSumSubStatementThunk(token)).then((res) => {
            const accessToken = res.data.accessToken.response.token;
            // Initialize the Sumsub SDK
            let snsWebSdkInstance = sumsubWebSdk
                .init(
                    accessToken,
                    // token update callback, must return Promise
                    // Access token expired
                    // get a new one and pass it to the callback to re-initiate the WebSDK
                    () => accessToken
                )
                .withConf({
                    lang: "en", //language of WebSDK texts and comments (ISO 639-1 format)
                    email: (userData.email ?? undefined),
                    phone: (userData.phoneNumber ?? undefined),
                })
                .withOptions({ addViewportTag: false, adaptIframeHeight: true })
                // see below what kind of messages WebSDK generates
                .on("idCheck.onStepCompleted", (payload) => {
                    console.log("onStepCompleted", payload);
                })
                .on("idCheck.onError", (error) => {
                    console.log("onError", error);
                })
                .build();

            console.log('sdk', snsWebSdkInstance);

            // Launch the SDK
            try {
                snsWebSdkInstance.launch('#sumsub-container .sumsub-area');

            } catch (launchError) {
                console.error('Error launching Sumsub SDK:', launchError);
            }
        }).catch((error) => {
            console.error('Error fetching access token or initializing Sumsub:', error);
        }).finally(() => setIsLoading(false))
    };

    return (
        <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
        >
            { !newKycList?.status ? (
                <div className={s.verification_block}>
                    <div className={s.verification_flex}>
                        <img src={personalKyc} alt="Personal KYC" />
                        <div className={s.title}>KYC for Personal Account</div>
                    </div>
                    <div className={s.button_wrapper}>
                        <MyBtn title={"Verification"} onClick={() => props.setCurrent(1)} />
                    </div>
                </div>
            ) : (
                <div className={s.questionnaire_list_block}>
                    <div className={s.questionnaire_title}>Questionnaire List</div>
                    <div className={s.questionnaire_table}>
                        <div className={s.table_header}>
                            <div className={s.header_value}>Owner</div>
                            <div className={s.header_value}>State</div>
                            { newKycList.status === "Rejected" && (
                                <div className={s.header_value}>Action</div>
                            )}
                        </div>
                        <div className={s.table_body}>
                            <div key={newKycList.id} className={s.table_row}>
                                <div className={s.row_value}>
                                    <div className={s.name}>{newKycList.owner}</div>
                                </div>
                                <div className={s.row_value}>
                                    {newKycList.status === 'Rejected' && (
                                        <div className={s.rejected}>Rejected</div>
                                    )}
                                    {newKycList.status === 'Confirmed' && (
                                        <div className={s.confirmed}>Confirmed</div>
                                    )}
                                    {newKycList.status === 'Pending' && (
                                        <div className={s.pending}>Pending</div>
                                    )}
                                </div>
                                { newKycList.status === 'Rejected' && (
                                    <div className={classnames(s.row_value, s.flex, s.gap)}>
                                        <div className={classnames(s.flex, s.action)}
                                             onClick={() => props.setCurrent(2)}>
                                            <div className={s.edit_active}></div>
                                            <div className={s.edit_text_active}>Edit</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={s.questionnaire_table_mobile}>
                        { newKycList?.status && (
                            <div key={newKycList.id}>
                                <div className={s.row_mobile}>
                                    <div className={s.block}>
                                        <div className={s.label}>OWNER</div>
                                        <div className={s.name}>{newKycList.owner}</div>
                                    </div>
                                    <div className={s.block}>
                                        <div className={s.label}>State</div>
                                        <div className={s.row_value}>
                                            {newKycList.status === 'Rejected' && (
                                                <div className={s.rejected}>Rejected</div>
                                            )}
                                            {newKycList.status === 'Confirmed' && (
                                                <div className={s.confirmed}>Confirmed</div>
                                            )}
                                            {newKycList.status === 'Pending' && (
                                                <div className={s.pending}>Pending</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                { newKycList.status === 'Rejected' && (
                                    <div className={classnames(s.row_value, s.flex, s.gap)}>
                                        <div className={classnames(s.flex, s.action)}
                                             onClick={() => props.setCurrent(2)}>
                                            <div className={s.edit_active}></div>
                                            <div className={s.edit_text_active}>Edit</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {isShowSumSub && (
                        <div id="sumsub-container" style={{ height: '100vh', width: '100%', top: 0, left: 0, position: "fixed", background: '#ffffff', zIndex: 120 }}>
                            <div className="sumsub-area" style={{ height: '100vh' }} >
                                <p style={{ margin: 'auto', marginTop: '30px', fontSize: '16px', textAlign: 'center' }}>Loading ...</p>
                            </div>
                            <style>{`
                            .sumsub-area iframe {
                                height: 100% !important;
                            }
                        `}</style>
                            <button
                                style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    zIndex: 121,
                                    background: "#ff4d4f",
                                    color: "#fff",
                                    border: "none",
                                    padding: "10px 20px",
                                    cursor: "pointer",
                                    borderRadius: "5px",
                                }}
                                onClick={() => setIsShowSumSub(false)}
                            >
                                Close
                            </button>

                        </div>
                    )}
                </div>
            )}
            {!userData.biometricValidation && (
                <div className={s.verification_block}>
                    <div className={s.verification_flex}>
                        <img src={personalKyc} alt="" />
                        <div className={s.title}>
                            Identity Verification Required (Face Detection)
                            <p className={s.description}>** Please complete your KYC verification to proceed with Face Detection.<br /><span>If you've already did your identity verification, kindly wait for confirmation.</span></p>
                        </div>
                    </div>
                    <div className={s.button_wrapper}>
                        <MyBtn title={isLoading ? 'Loading...' : 'Sumsub Verification'} localDisable={!newKycList?.status}
                            onClick={startSumsubVerification} />
                    </div>
                </div>
            )}
        </motion.div>
    );
};

export default KycPersonalStepOne;
