import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Collapse, Row, Col } from "antd";
import Icon, { DeleteOutlined } from "@ant-design/icons";
import { ReactComponent as BackIcon } from "../../assets/images/personalAccount/back.svg";
import MyBtn from "../ui/MyBtn/MyBtn";
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import {
  getPrincipalInitialValuesByType,
  getPrincipalValidationSchemaByType,
  IPrincipalData,
  principalMapping,
  PrincipalType,
  principalTypeOptions,
  requiredDocuments,
} from "./Constants/PrincipalConstants";
import KycFormPrincipal from "./Templates/KycFormPrincipal";
import styles from "./GeneratePrincipalForm.module.css";
import {
  confirmKycThunk,
  createPrincipalThunk,
  updatePrincipalThunk,
  getPrincipalListThunk,
} from "../../store/reducers/ActionCreators";
import { useAppDispatch } from "../../store/store";
import { useAppSelector } from "../../hooks/redux";
import { toBase64 } from "../../helpers/toBase64";
import CustomModal from "../ui/CustomModal/CustomModal";
import { phonecodes } from "../../constants/StaticData";

const getCountryCode = (countryName: string | null | undefined): string | undefined => {
  if (!countryName) return undefined;
  const country = phonecodes.find((entry) => entry.na === countryName);
  return country?.co;
};

const getCountryNameFromCode = (code: string | null | undefined): string | undefined => {
  if (!code) return undefined;
  const country = phonecodes.find(
    (entry) => entry.co && entry.co.toUpperCase() === code.toUpperCase()
  );
  return country?.na;
};

const { Panel } = Collapse;

type PropsType = {
  current: number;
  setCurrent: Dispatch<SetStateAction<number>>;
};

const GeneratePrincipalForm = ({ current, setCurrent }: PropsType) => {
  const [principals, setPrincipals] = useState<IPrincipalData[]>([]);
  const [docsArray, setDocsArray] = useState<any[]>([]);
  const [createModeMissingDoc, setCreateModeMissingDoc] = useState<string[]>([]);
  const [activeKeys, setActiveKeys] = useState<number[]>([]);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Something went wrong. Please try again later."
  );
  const [successMessage, setSuccessMessage] = useState(
    "Your details have been sent for review."
  );

  const hasSignatory = principals.some((p) => p.isSignatory);

  const token = useAppSelector((state) => state.userReducer.user.userToken);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPrincipalListThunk(token));
  }, [dispatch, token]);

  const principalData: any = useAppSelector(
    (state) => state.userReducer.user.principalList
  );

  useEffect(() => {
    if (principalData && principalData.data) {
      setPrincipals((prev) => {
        const savedPrincipals = principalData.data.map((p: IPrincipalData) => ({
          ...p,
          isSaved: true,
          type:
            Object.keys(principalMapping).find(
              (key) => principalMapping[key] === p.type
            ) || p.type,
          country: getCountryNameFromCode(p.country) || p.country,
        }));
        const unsavedPrincipals = prev.filter((p) => !p.id);
        return [...savedPrincipals, ...unsavedPrincipals];
      });
      setDocsArray(principalData.data.map(() => ({})));
    }
  }, [principalData]);

  const handleSave = async (index: number, data: IPrincipalData) => {
    const docsForThisPrincipal = docsArray[index] || {};
    const isUpdate = (data as any).id !== undefined;
    const missingFilesCreateMode = requiredDocuments[
      data.type as keyof typeof requiredDocuments
    ].filter((file: string) => {
      if (docsForThisPrincipal[file]) return false;
      if (isUpdate && data.files && data.files[file]) return false;
      return true;
    });

    setCreateModeMissingDoc(missingFilesCreateMode);
    if (missingFilesCreateMode.length > 0) return;

    const tempData = { files: {} as Record<any, any> };
    for (const [key, file] of Object.entries(docsForThisPrincipal)) {
      if (file) {
        tempData.files[key] = await toBase64(file);
      }
    }
    const allowedKeys = Object.keys(getPrincipalInitialValuesByType(data.type)) as (keyof IPrincipalData)[];
    const filteredData = allowedKeys.reduce((acc, key) => {
      if (key in data) {
        acc[key] = data[key];
      }
      return acc;
    }, {} as Partial<IPrincipalData>);

    filteredData.type = data.type;
    if (principalMapping[data.type] !== PrincipalType.AGENT) {
      filteredData.isSignatory = data.isSignatory;
    }
    const finalisedData = { ...filteredData, ...tempData.files };
    finalisedData.type = principalMapping[finalisedData.type];

    if (finalisedData.country) {
      finalisedData.country = (getCountryCode(finalisedData.country) ||
        finalisedData.country)?.toUpperCase();
    }

    const { isSaved, ...finalDataWithoutSaved } = finalisedData;

    const thunkAction = isUpdate
      ? updatePrincipalThunk(token, (data as any).id, finalDataWithoutSaved)
      : createPrincipalThunk(token, finalDataWithoutSaved);

    dispatch(thunkAction)
      .then((res: any) => {
        const updated = [...principals];
        let newPrincipal = isUpdate ? data : { ...data, id: res.data?.id || res.id };
        const newFilesMapping = { ...(newPrincipal.files || {}) };
        for (const key in docsForThisPrincipal) {
          if (docsForThisPrincipal[key]) {
            newFilesMapping[key] = true;
          }
        }
        newPrincipal.files = newFilesMapping;
        updated[index] = { ...newPrincipal, isSaved: true };
        setPrincipals(updated);
        setActiveKeys((prev) => prev.filter((key) => key !== index));
        setSuccessMessage(
          `Principal has been successfully ${isUpdate ? "updated" : "created"}!`
        );
        setIsSuccessModalOpen(true);
      })
      .catch((error: any) => {
        console.error(
          `Error ${isUpdate ? "updating" : "creating"} principal:`,
          error
        );
        setErrorMessage("Failed to save principal. Please try again.");
        setIsErrorModalOpen(true);
      });
  };

  const handleSignatoryToggle = (index: number, newVal: boolean) => {
    const updated = principals.map((p, i) =>
      i === index ? { ...p, isSignatory: newVal } : { ...p, isSignatory: false }
    );
    setPrincipals(updated);
  };

  const addPrincipal = () => {
    const last = principals[principals.length - 1];
    if (principals.length > 0 && !last?.isSaved) return;
    setPrincipals((prev) => [...prev, getPrincipalInitialValuesByType("default")]);
    setDocsArray((prev) => [...prev, {}]);
    setActiveKeys((prev) => [...prev, principals.length]);
  };

  const handleNextStep = async () => {
    if (hasSignatory) {
      try {
        await dispatch(confirmKycThunk(token));
        setCurrent(3);
        setIsSuccessModalOpen(true);
      } catch (error: any) {
        console.error("Error occurred while confirming KYC:", error);
        const responseData = error?.response?.data;
        const errorMsg =
          responseData?.errors?.confirmation ||
          error?.message ||
          "Something went wrong, please contact support!";
        if (error?.response?.status === 400) {
          setErrorMessage(errorMsg);
        } else {
          setErrorMessage("Something went wrong, please contact support!");
        }
        setIsErrorModalOpen(true);
      }
    } else {
      setErrorMessage("You cannot confirm with no signatories");
      setIsErrorModalOpen(true);
    }
  };

  const handleTypeChanged = (index: number, newType: string) => {
    setPrincipals((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        ...getPrincipalInitialValuesByType(newType),
        type: newType,
      };
      if (newType === "Signatory") {
        updated[index].isSignatory = true;
      }
      return updated;
    });

    setDocsArray((prev) => {
      const updated = [...prev];
      updated[index] = {};
      return updated;
    });
  };

  const getPrincipalLabel = (type: string) => {
    if (type === "") return "Select Principal Type";
    const found = principalTypeOptions.find((opt) => opt.label === type);
    return found ? found.label : "Select Type";
  };

  const removePrincipal = (index: number) => {
    if (principals[index].id) return;
    setPrincipals((prev) => prev.filter((_, i) => i !== index));
    setDocsArray((prev) => prev.filter((_, i) => i !== index));
    setActiveKeys((prev) => prev.filter((key) => key !== index));
  };

  return (
    <div className={styles.principalContainer}>
      <h2 className={styles.containerTitle}>Principal Officers</h2>
      <Collapse
        activeKey={activeKeys}
        onChange={(keys) => {
          if (typeof keys === "string" || typeof keys === "number") {
            setActiveKeys([Number(keys)]);
          } else if (Array.isArray(keys)) {
            setActiveKeys(keys.map(Number));
          }
        }}
        size="large"
        accordion
      >
        {principals.map((principal, index) => (
          <Panel
            key={index}
            header={
              <div className={styles.panelHeader}>
                <strong>
                  Principal #{index + 1}: {getPrincipalLabel(principal.type)}
                </strong>
                {principal.id || principal.isSaved ? (
                  <span className={styles.savedTag}>Saved</span>
                ) : (
                  <span
                    className={styles.trashIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      removePrincipal(index);
                    }}
                  >
                    <DeleteOutlined style={{ color: "red", fontSize: "16px" }} />
                  </span>
                )}
              </div>
            }
            className={styles.panelItem}
          >
            <KycFormPrincipal
              initialData={principal}
              validationSchema={getPrincipalValidationSchemaByType(principal.type)}
              index={index}
              hasSignatory={hasSignatory}
              documents={docsArray[index] || {}}
              createModeMissingDoc={createModeMissingDoc}
              onSave={handleSave}
              onSignatoryToggle={handleSignatoryToggle}
              onTypeChanged={handleTypeChanged}
              setDocuments={(newDocs) => {
                setDocsArray((prev) => {
                  const updated = [...prev];
                  updated[index] = newDocs;
                  return updated;
                });
              }}
            />
          </Panel>
        ))}
      </Collapse>
      <Row gutter={[16, 24]} className={styles.actionRow}>
        <Col span={24}>
          <MyBtn
            title="Add Another Principal"
            localDisable={principals.some((p) => !p.isSaved)}
            onClick={addPrincipal}
          />
        </Col>
        <Col span={12}>
          <TransparentButton
            large
            icon={<Icon component={() => <BackIcon />} />}
            title="Back"
            type="button"
            onClick={() => setCurrent(current - 1)}
          />
        </Col>
        <Col span={12}>
          <MyBtn
            title="Confirm Principals"
            onClick={handleNextStep}
            localDisable={principals.some((p) => !p.isSaved)}
          />
        </Col>
      </Row>
      <CustomModal
        isModalOpen={isSuccessModalOpen}
        setIsModalOpen={setIsSuccessModalOpen}
      >
        <div className={styles.flex}>
          <div className={styles.modal_title}>Success!</div>
          <div className={styles.modal_subtitle}>{successMessage}</div>
          <div className={styles.button_block}>
            <MyBtn
              title={"Ok"}
              onClick={() => {
                setIsSuccessModalOpen(false);
              }}
            />
          </div>
        </div>
      </CustomModal>
      <CustomModal
        type="error"
        isModalOpen={isErrorModalOpen}
        setIsModalOpen={setIsErrorModalOpen}
      >
        <div className={styles.flex}>
          <div className={styles.modal_title}>Oops!</div>
          <div className={styles.modal_subtitle}>{errorMessage}</div>
          <div className={styles.button_block}>
            <MyBtn
              buttonType="error"
              title={"Ok"}
              onClick={() => setIsErrorModalOpen(false)}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default GeneratePrincipalForm;
