import React, { Dispatch, SetStateAction } from 'react'
import CustomModalTabList from '../../../../../components/ui/CustomModalTabList/CustomModalTabList';
import CurrencyExchange from '../../../Currency/CurrencyExchange/CurrencyExchange';

type PropsType = {
    s: any;
    usdtAccounts: any;
    isModalOpen: any;
    setIsModalOpen: Dispatch<SetStateAction<any>>;
}

const RequestTransactionModal = (props: PropsType) => {

    const {
        s,
        usdtAccounts,
        isModalOpen,
        setIsModalOpen
    } = props;

    return (
        <CustomModalTabList
            isModalOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            tabContents={{
                Buy: (
                    <React.Fragment>
                        <div className={s.modalTitle}>
                            Buy USDT
                        </div>
                        {usdtAccounts.length > 0 ? <CurrencyExchange transactionType="buy" usdtAccounts={usdtAccounts} /> : <div className={s.walletText}>You must have a USDT account to access this feature.</div>}
                    </React.Fragment>
                ),
                Sell: (
                    <React.Fragment>
                        <div className={s.modalTitle}>
                            Sell USDT
                        </div>
                        {usdtAccounts.length > 0 ? <CurrencyExchange transactionType="sell" usdtAccounts={usdtAccounts} /> : <div className={s.walletText}>You must have a USDT account to access this feature.</div>}
                    </React.Fragment>
                )
            }}
            tabs={[
                { label: "Buy" },
                { label: "Sell" },
            ]}
        />
    )
}

export default RequestTransactionModal;